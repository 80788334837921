import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons/faPenToSquare";
import { faXmark } from "@fortawesome/free-solid-svg-icons/faXmark";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";

/**
 * Creates a JIRA-style click-to-edit field for the integration name.
 */
export const ClickToEdit = (props: { text: string; onChange: (text: string) => void }) => {
  const ref = useRef<HTMLInputElement>(null);
  const [text, setText] = useState(props.text);
  const [originalText, setOriginalText] = useState(props.text);
  const [editing, setEditing] = useState<boolean>(false);
  const [shouldFocus, setShouldFocus] = useState<boolean>(false);

  // make sure we don't have stale data if the props have changed
  // (but compare against the original field so we don't get a flash of the pre-edit value)
  useEffect(() => {
    if (props.text !== originalText && !editing) {
      setOriginalText(props.text);
      setText(props.text);
    }
  }, [props.text, text, originalText, editing]);

  // focus immediately after render
  useEffect(() => {
    if (shouldFocus && ref.current) {
      ref.current.focus();
      setShouldFocus(false);
    }
  }, [shouldFocus]);

  const handleCancel = () => {
    setEditing(false);
    setText(props.text);
  };

  const handleSave = () => {
    if (text) {
      setEditing(false);
      props.onChange(text);
    } else {
      setText(props.text);
    }
  };

  if (!editing) {
    return (
      <span
        className="hover:bg-gray-200 block leading-[1.5]" // leading-[1.5] so the text doesn't move when edited
        onClick={(e) => {
          setEditing(true);
          setShouldFocus(true);
        }}
      >
        {text}
        <button className="ml-2 opacity-10 hover:opacity-100" aria-label="Edit" title="Edit" tabIndex={0}>
          <FontAwesomeIcon icon={faPenToSquare} />
        </button>
      </span>
    );
  } else {
    return (
      <div className="flex">
        <input
          className="grow"
          title="Edit integration name"
          ref={ref}
          value={text}
          onChange={(e) => setText(e.target.value)}
          onBlur={(e) => handleSave()}
          onKeyDown={(e) => {
            if (e.key === "Escape") {
              handleCancel();
            } else if (e.key === "Enter") {
              handleSave();
            }
          }}
        />
        <div className="inline-block flex-none mx-1 leading-[1.5]">
          <button
            className="mx-1"
            aria-label="Save changes"
            title="Save changes"
            onMouseDown={(e) => {
              // suppress the blur event since we're handling it here
              e.preventDefault();
            }}
            onClick={() => handleSave()}
          >
            <FontAwesomeIcon icon={faCheck} />
          </button>

          <button
            className="mx-1"
            aria-label="Discard changes"
            title="Discard changes"
            onMouseDown={(e) => {
              // suppress the blur event since we're effectively cancelling it here
              e.preventDefault();
            }}
            onClick={() => handleCancel()}
          >
            <FontAwesomeIcon icon={faXmark} />
          </button>
        </div>
      </div>
    );
  }
};

import { useQuery } from "@tanstack/react-query";
import { useIntegrationAuthorization } from "../../auth";
import { fetchOrganizationInfoAsync } from "./api";

export const useOrganizationInfo = (
  integrationId: string,
  premiumAppIntegrationTypeId: string,
  environment: string | undefined,
) => {
  const { isAuthorized, authorization } = useIntegrationAuthorization();

  return useQuery({
    queryKey: ["genesys", "orgInfo", integrationId],
    queryFn: () => fetchOrganizationInfoAsync(environment!, premiumAppIntegrationTypeId, authorization!),
    enabled: !!environment && isAuthorized,
    staleTime: 300000,
  });
};

import { useEffect, useMemo } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import { IngestIntegration } from "../../../model";
import { IntegrationAuthorizationContextProvider } from "../auth";
import { IntegrationErrorAlert } from "../components/alerts/IntegrationErrorAlert";
import { DangerButton } from "../components/buttons";
import { ClickToEdit } from "../components/ClickToEdit";
import { IntegrationsNavLink } from "../components/IntegrationsNavLink";
import { isNotFoundError } from "../errors";
import { useConnectors, useDeleteIntegration, useIntegration, useUpdateIntegration } from "../hooks";
import { DataIntgApiKeyView } from "../views/dataintg-apikey/DataIntgApiKeyView";
import { DataIntgWebhookView } from "../views/dataintg-webhook/DataIntgWebhookView";
import { GenesysIntegrationView } from "../views/genesys/GenesysIntegrationView";
import { IntegrationViewProps } from "../views/types";
import { FallbackIntegrationView } from "../views/_fallback/FallbackIntegrationView";

const views: Record<string, React.FC<IntegrationViewProps>> = {
  "genesys": GenesysIntegrationView,
  "dataintg-apikey": DataIntgApiKeyView,
  "dataintg-webhook": DataIntgWebhookView,
};

const IntegrationPage = () => {
  const { integrationId } = useParams();

  const {
    data: integration,
    // isLoading: integrationIsLoading,
    error: integrationError,
  } = useIntegration(integrationId!);

  useEffect(() => {
    window.document.title = integration?.name ?? "Aveni Integration";
  }, [integration]);

  const { mutate: updateIntegration } = useUpdateIntegration(integrationId!);

  const { data: connectors, isLoading: connectorsLoading } = useConnectors();

  const connector = useMemo(
    () => (integration && connectors ? connectors.find((c) => c.connectorId === integration.connectorId) : undefined),
    [integration, connectors],
  );

  if (!integrationId && isNotFoundError(integrationError)) return <Navigate to="/integrations" replace={true} />;

  if (!integration || connectorsLoading || !connector) {
    return (
      <div className="max-w-screen-lg m-auto px-4 py-8 flex flex-col space-y-4">
        <h1 className="text-3xl font-light leading-[1.5]">
          <span className="text-aveni-blue">Integration</span>
          <span className="text-neutral-500 font-extralight"> loading...</span>
        </h1>
        <PulseLoader color="gray" />
      </div>
    );
  } else if (integrationError || !integration) {
    return <IntegrationErrorAlert error={integrationError} />;
  } else if (integration.deleted) {
    return <Navigate to="/integrations" />;
  }

  const IntegrationView = views[integration.connectorId] ?? FallbackIntegrationView;

  return (
    <>
      <div className="max-w-screen-lg m-auto px-4 py-8 flex flex-col space-y-4">
        <div>
          <div className="flex items-center space-x-4">
            <h1 className="grow text-3xl text-aveni-blue font-light">
              <ClickToEdit
                text={integration.name}
                onChange={(name) => {
                  updateIntegration({ name });
                }}
              />
            </h1>
          </div>
          <div>
            <div className="flex space-x-4">
              <p className="grow text-neutral-600 text-lg">{connector.label}</p>
              <button
                className="text-red-500 font-light underline hover:opacity-100 hover:text-red-600 transition duration-150 ease-in-out"
                data-bs-toggle="modal"
                data-bs-target="#uninstallModalDialog"
              >
                Uninstall
              </button>
            </div>
            <p className="text-neutral-500 font-light italic">{connector.description}</p>
          </div>
        </div>
        <IntegrationsNavLink />
        <IntegrationAuthorizationContextProvider integration={integration}>
          <IntegrationView integration={integration} connector={connector} />
        </IntegrationAuthorizationContextProvider>
      </div>
      <UninstallIntegrationModalDialog integration={integration} />
    </>
  );
};

const UninstallIntegrationModalDialog = (props: { integration: IngestIntegration }) => {
  const { integration } = props;

  const navigate = useNavigate();
  const { mutateAsync: uninstallIntegration, isLoading: isDeleting } = useDeleteIntegration(integration.integrationId);

  const onUninstall = () => {
    uninstallIntegration().then(() => navigate("/integrations"));
  };

  // note that in future we may need to request authorization before a delete

  return (
    <div
      className="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
      id="uninstallModalDialog"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      aria-labelledby="uninstallModalDialogLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog relative w-auto pointer-events-none">
        <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
          <div className="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
            <span className="text-xl font-medium leading-normal text-gray-800" id="uninstallModalDialogLabel">
              Confirm Uninstall
            </span>
            <button
              type="button"
              className="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body relative p-4">
            {integration.state.startsWith("active") ? (
              <>
                <p>
                  <span>This integration is currently </span>
                  <span className="font-semibold">{integration.state === "active" ? "active" : "activating"}</span>.
                </p>
                <p className="mt-2">
                  <span>Are you sure you want to uninstall instead pressing the </span>
                  <span className="text-sm uppercase">Deactivate</span>
                  <span> button?</span>
                </p>
              </>
            ) : (
              <>
                <p>
                  <span>This integration is currently </span>
                  <span className="font-semibold">
                    {integration.state === "inactive" ? "deactivated" : "deactivating"}
                  </span>
                  .
                </p>
                <p className="mt-2">Are you sure you want to uninstall?</p>
              </>
            )}
          </div>
          <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-center p-4 border-t border-gray-200 rounded-b-md">
            <DangerButton onClick={onUninstall} disabled={isDeleting} data-bs-dismiss="modal">
              <span>Uninstall</span>
              {isDeleting && (
                <span className="ml-2">
                  <PulseLoader color="white" size="8px" />
                </span>
              )}
            </DangerButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntegrationPage;

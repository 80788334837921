import { PulseLoader } from "react-spinners";
import { IngestIntegration } from "../../../../model";
import { useIntegrationAuthorization } from "../../auth";
import { useDeploymentStatus, useOptimisticUpdateIntegration } from "../../hooks";
import { AuthorizationExpiringAlert } from "../alerts/AuthorizationExpiringAlert";
import { ErrorAlert } from "../alerts/ErrorAlert";
import { DangerButton, LightButton, PrimaryButton } from "../buttons";
import { DeploymentStatusBadge } from "./DeploymentStatusBadge";

export type ActivationPromptProps = {
  integration: IngestIntegration;
  active?: React.ReactNode | React.ReactNode[];
  activePending?: React.ReactNode | React.ReactNode[];
  inactive?: React.ReactNode | React.ReactNode[];
  inactivePending?: React.ReactNode | React.ReactNode[];
};

export const ActivationPrompt: React.FC<ActivationPromptProps> = (props) => {
  const { integration } = props;
  const { integrationId, state, dirty, authorization } = integration;
  const canReauthorize = !!authorization;

  const { authorize, isAuthorizing, isExpiring: isAuthorizationExpiring } = useIntegrationAuthorization();

  const { mutate: updateIntegrationState, isError: isUpdateStateError } = useOptimisticUpdateIntegration(integrationId);
  const { data: deploymentStatus } = useDeploymentStatus(integrationId, integration.trackingToken);

  const setActive = () => {
    updateIntegrationState({ state: "active" });
  };

  const setInactive = () => {
    updateIntegrationState({ state: "inactive" });
  };

  switch (state) {
    case "active":
      return (
        <div className="block p-6 mb-6 rounded-lg shadow-lg bg-white">
          <h2 className="text-gray-900 text-lg leading-tight font-medium mb-2">
            {dirty ? "Activated — Changes Pending" : "Activated"}
          </h2>
          <div className="text-gray-700 text-base mb-4 flex flex-col space-y-2">
            {isUpdateStateError && <ActivationError />}
            {isAuthorizationExpiring && <AuthorizationExpiringAlert />}
            {props.active}
            {dirty && <p>You have made configuration changes that have not yet been applied.</p>}
          </div>
          <div className="flex space-x-2">
            <div className="flex space-x-4">
              <PrimaryButton onClick={setActive} disabled={!dirty || isAuthorizing}>
                Activate
              </PrimaryButton>
              {!!deploymentStatus && <DeploymentStatusBadge status={deploymentStatus} />}
            </div>
            <div className="flex grow space-x-2 justify-end">
              <DangerButton onClick={setInactive} disabled={isAuthorizing}>
                Deactivate
              </DangerButton>
              {canReauthorize && <ReauthorizeButton authorize={authorize} isAuthorizing={isAuthorizing} />}
            </div>
          </div>
        </div>
      );

    case "active-pending":
      return (
        <div className="block p-6 mb-6 rounded-lg shadow-lg bg-white">
          <h2 className="text-gray-900 text-lg leading-tight font-medium mb-2">
            {dirty ? "Activating — Changes Pending" : "Activating"}
            <span className="ml-2">
              <PulseLoader color="#2563EB" size="10px" />
            </span>
          </h2>
          <div className="text-gray-700 text-base mb-4 flex flex-col space-y-2">
            {isUpdateStateError && <ActivationError />}
            {isAuthorizationExpiring && <AuthorizationExpiringAlert />}
            {props.activePending}
            {dirty && <p>You have made further configuration changes that have not yet been applied.</p>}
          </div>
          <div className="flex space-x-2">
            <div className="flex space-x-4">
              <PrimaryButton onClick={setActive} disabled={!dirty || isAuthorizing}>
                Activate
              </PrimaryButton>
              {!!deploymentStatus && <DeploymentStatusBadge status={deploymentStatus} />}
            </div>
            <div className="flex grow space-x-2 justify-end">
              <DangerButton onClick={setInactive} disabled={isAuthorizing}>
                Deactivate
              </DangerButton>
              {canReauthorize && <ReauthorizeButton authorize={authorize} isAuthorizing={isAuthorizing} />}
            </div>
          </div>
        </div>
      );

    case "inactive":
      return (
        <div className="block p-6 mb-6 rounded-lg shadow-lg bg-white">
          <h2 className="text-gray-900 text-lg leading-tight font-medium mb-2">Ready to Activate</h2>
          <div className="text-gray-700 text-base mb-4 flex flex-col space-y-2">
            {isUpdateStateError && <ActivationError />}
            {isAuthorizationExpiring && <AuthorizationExpiringAlert />}
            {props.inactive}
          </div>
          <div className="flex space-x-2">
            <div className="flex space-x-4">
              <PrimaryButton onClick={setActive} disabled={!dirty || isAuthorizing}>
                Activate
              </PrimaryButton>
              {!!deploymentStatus && <DeploymentStatusBadge status={deploymentStatus} />}
            </div>
            <div className="flex grow space-x-2 justify-end">
              {canReauthorize && <ReauthorizeButton authorize={authorize} isAuthorizing={isAuthorizing} />}
            </div>
          </div>
        </div>
      );

    case "inactive-pending":
      return (
        <div className="block p-6 mb-6 rounded-lg shadow-lg bg-white">
          <h2 className="text-gray-900 text-lg leading-tight font-medium mb-2">
            Deactivating{" "}
            <span className="ml-2">
              <PulseLoader color="red" size="10px" />
            </span>
          </h2>
          <div className="text-gray-700 text-base mb-4 flex flex-col space-y-2">
            {isUpdateStateError && <ActivationError />}
            {isAuthorizationExpiring && <AuthorizationExpiringAlert />}
            {props.inactivePending}
          </div>
          <div className="flex space-x-2">
            <div className="flex space-x-4">
              <PrimaryButton onClick={setActive} disabled={!dirty || isAuthorizing}>
                Activate
              </PrimaryButton>
              {!!deploymentStatus && <DeploymentStatusBadge status={deploymentStatus} />}
            </div>
            <div className="flex grow space-x-2 justify-end">
              <DangerButton disabled={true}>Deactivate</DangerButton>
              {canReauthorize && <ReauthorizeButton authorize={authorize} isAuthorizing={isAuthorizing} />}
            </div>
          </div>
        </div>
      );
  }
};

const ActivationError = () => {
  return (
    <div className="mb-2">
      <ErrorAlert>
        <p>The previous operation didn't work as expected.</p>
        <p>Contact Aveni for support if the problem persists.</p>
      </ErrorAlert>
    </div>
  );
};

const ReauthorizeButton = (props: { authorize: () => void; isAuthorizing: boolean }) => {
  if (props.isAuthorizing) {
    return (
      <LightButton disabled={true}>
        <span>
          Re-authorize{" "}
          <span className="ml-2 align-baseline">
            <PulseLoader color="gray" size="8px" />
          </span>
        </span>
      </LightButton>
    );
  } else {
    return <LightButton onClick={props.authorize}>Re-authorize</LightButton>;
  }
};

import { ErrorAlert } from "../../components/alerts/ErrorAlert";
import { toErrorMessage } from "../../errors";

export const GenesysOrgInfoError = (props: { error: unknown }) => {
  return (
    <ErrorAlert>
      <p className="font-medium text-lg mb-2">Genesys Cloud Problem</p>
      <p>Unable to fetch information from Genesys Cloud CX</p>
      <p>Contact Aveni support if the problem persists</p>
      <p className="mt-2 text-sm">{toErrorMessage(props.error)}</p>
    </ErrorAlert>
  );
};

export type DataIntgApiKeyHelpCardProps = {
  apiKey: string;
};

export const DataIntgApiKeyHelpCard: React.FC<DataIntgApiKeyHelpCardProps> = (props) => {
  const { apiKey } = props;

  return (
    <section className="block p-6 mb-6 rounded-lg shadow-lg bg-white">
      <h2 className="text-gray-900 text-lg leading-tight font-medium mb-2">Instructions</h2>
      <article className="text-gray-700 prose max-w-none">
        <p>
          Include the API Key as an <code>X-API-Key</code> header.
        </p>
        <p>Example:</p>
        <pre className="w-full">curl -H X-API-Key={apiKey} https://data.avenidetect.com/api/v1/assessments</pre>
        <p>
          Try this API key in the{" "}
          <a href="/docs/data-integration/api" target="_blank">
            Aveni Data Integration API browser
          </a>
          .
        </p>
      </article>
    </section>
  );
};

import config from "../../config";

const DataIntgApiDocs: React.FC = (props) => {
  const openapiUrl = `https://${config.dataIntegration.domain}/docs/v1/openapi.json`;

  // simpler than swagger-ui-react as it avoids dependency and css issues
  // and the iframe enables us to pass the URL to the OpenAPI spec
  return (
    <iframe
      title="doc"
      src={`swagger-ui-embed.html?openapiUrl=${encodeURIComponent(openapiUrl)}`}
      className="w-full min-h-screen"
    />
  );
};

export default DataIntgApiDocs;

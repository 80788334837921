import { faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import genesysIcon from "../../assets/catalog/genesys/icon.png";
import aveniLogo from "../../assets/images/aveni.png";
import { PrimaryButton } from "../../components/buttons";

/**
 * We need an unprotected landing page for the Genesys Cloud CX interface to embed as an iframe,
 * since the Auth0 New Universal Login does not allow framing to prevent clickjacking.
 */
const GenesysStartPage: React.FC = () => {
  const [params] = useSearchParams();
  const [isInstalling, setInstalling] = useState(false);

  /**
   * Install using a new tab so we're not iframed anymore.
   */
  const onInstall = () => {
    // pass along any URL parameters as they'll be needed to select the environment.
    const url = new URL(window.location.origin);
    url.pathname = "/catalog/genesys/setup";
    params.forEach((value, key) => url.searchParams.append(key, value));
    window.open(url, "_blank");

    // existing page will be left open so probably best to disable the button
    setInstalling(true);
  };

  useEffect(() => {
    window.document.title = "Start Installation";
  }, []);

  return (
    <div className="flex h-screen">
      <div className="m-auto  p-6 rounded-lg shadow-lg bg-white flex flex-col space-y-4">
        <div className="flex space-x-4 text-3xl h-[32px] items-center m-2 self-center">
          <img className="mb-1" src={genesysIcon} alt="Genesys Cloud CX" />
          <span className="pl-3">
            <FontAwesomeIcon icon={faArrowRight} className="text-neutral-600 animate-pulse " />
          </span>
          <img className="h-[40px]" src={aveniLogo} alt="Aveni" />
        </div>
        <h1 className="text-3xl text-neutral-800 font-light text-center ">Connect to Aveni</h1>
        <div className="prose">
          <p>
            This integration will enable Aveni to automatically process calls from your Genesys Cloud CX organisation.
          </p>
          <p>Aveni will have access to:</p>
          <ul>
            <li>Call recordings</li>
            <li>Call metadata &mdash; date, time, contact details, etc.</li>
            <li>Information about agents and customers stored in Genesys</li>
          </ul>
          <p>
            To complete this setup, you will need access to Aveni. If you have not already received an invitation,
            please talk to your Aveni representative.
          </p>
        </div>
        <div className="self-center pt-2">
          <PrimaryButton onClick={onInstall} disabled={isInstalling}>
            <span>Sign into Aveni to continue</span>
            <FontAwesomeIcon className="ml-1" icon={faArrowRight} />
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default GenesysStartPage;

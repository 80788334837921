import { useAuth0 } from "@auth0/auth0-react";
import { faLock } from "@fortawesome/free-solid-svg-icons/faLock";
import { faSignIn } from "@fortawesome/free-solid-svg-icons/faSignIn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { PrimaryButton } from "../components/buttons";

/**
 * Rendered when the user is authenticated but not authorized.
 *
 * We need to be careful not to cause a login loop, so it's important to render this from a
 * non-protected route. This could be routed from an actual URL but we prefer to render it
 * as alternate content from either the landing page or the ProtectedRoute HOC.
 */
const UnauthorizedPage: React.FC = () => {
  const { isAuthenticated, logout } = useAuth0();

  useEffect(() => {
    window.document.title = "No Access";
  }, []);

  if (!isAuthenticated) {
    return <Navigate to="/" replace={true} />;
  }

  const onSignIn = () => {
    logout({
      returnTo: window.location.origin,
    });
  };

  return (
    <div className="flex h-screen">
      <div className="m-auto  p-6 rounded-lg shadow-lg bg-white flex flex-col space-y-4">
        <div className="flex space-x-4 text-3xl h-[32px] items-center m-2 self-center">
          <FontAwesomeIcon icon={faLock} className="text-neutral-600" />
        </div>
        <h1 className="text-3xl text-neutral-800 font-light text-center ">No Access</h1>
        <div className="prose">
          <p>You don't have permission to use this application.</p>
          <p>
            If you are a System Administrator at your organisation, you can request access by contacting your Aveni
            representative.
          </p>
          <p>
            Or perhaps you wanted to go to <a href="https://avenidetect.com">https://avenidetect.com</a>?
          </p>
        </div>
        <div className="self-center pt-2">
          <PrimaryButton onClick={onSignIn}>
            <span>Back to Sign In</span>
            <FontAwesomeIcon className="ml-1" icon={faSignIn} />
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default UnauthorizedPage;

import {
  DomainAuthorizationConfiguration,
  IngestIntegrationAuthorizationConfiguration,
  IngestIntegrationAuthorizationType,
  OAuthAuthorizationCodeConfiguration,
  OAuthClientCredentialsConfiguration,
} from "@aveni-ingest/connectors-config";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { faExclamation } from "@fortawesome/free-solid-svg-icons/faExclamation";
import { faXmark } from "@fortawesome/free-solid-svg-icons/faXmark";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { PulseLoader } from "react-spinners";
import { Popover } from "react-tiny-popover";
import { IngestIntegration, IngestIntegrationAuthorization } from "../../../../model";
import { useIntegrationAuthorization } from "../../auth";
import { toErrorMessage } from "../../errors";
import { useUpdateIntegration } from "../../hooks";
import { Input, Label, Select } from "../forms";

export type AuthorizationConfigurationCardProps = {
  integration: IngestIntegration;
  authorizationType: IngestIntegrationAuthorizationType;
  authorizationDomains?: string[];
};

const AuthorizationConfigurationCard: React.FC<AuthorizationConfigurationCardProps> = (props) => {
  const { integration, authorizationDomains } = props;
  const { authorizationConfiguration: config } = integration;

  const { mutateAsync: updateIntegration, isLoading: isUpdatingIntegration } = useUpdateIntegration(
    integration.integrationId,
  );

  const { authorize, isAuthorizing, authorization, authorizationError } = useIntegrationAuthorization();

  const onAuthorize = (authorizationConfiguration: IngestIntegrationAuthorizationConfiguration) => {
    updateIntegration({
      authorizationConfiguration,
    }).then(authorize);
  };

  const authorizationType = integration.authorizationConfiguration?.type ?? props.authorizationType;

  const editorProps = {
    onAuthorize,
    isAuthorizing,
    authorizationError,
    authorizationType,
    authorization,
  };

  return (
    <section className="block p-6 mb-4 rounded-lg shadow-lg bg-white">
      <h2 className="text-gray-900 text-lg leading-tight font-medium mb-4">
        Authorization Configuration
        {(isUpdatingIntegration || isAuthorizing) && (
          <span className="ml-2">
            <PulseLoader color="#2563EB" size="10px" />
          </span>
        )}
      </h2>

      {authorizationType === "domain" && !authorizationDomains && (
        <DomainEditor config={config as DomainAuthorizationConfiguration} {...editorProps} />
      )}

      {authorizationType === "domain" && authorizationDomains && (
        <DomainSelectionEditor
          config={config as DomainAuthorizationConfiguration}
          {...editorProps}
          authorizationDomains={authorizationDomains}
        />
      )}

      {authorizationType === "oauth-client-credentials" && (
        <ClientCredentialsEditor config={config as OAuthClientCredentialsConfiguration} {...editorProps} />
      )}

      {(authorizationType === "oauth-authorization-code" || authorizationType === "oauth-authorization-code-pkce") && (
        <AuthorizationCodeEditor config={config as OAuthAuthorizationCodeConfiguration} {...editorProps} />
      )}
    </section>
  );
};

type AuthorizationEditorProps<T> = {
  config: T | undefined;
  onAuthorize: (config: T) => void;
  isAuthorizing: boolean;
  authorizationError: unknown;
  authorizationType: IngestIntegrationAuthorizationType;
  authorization: IngestIntegrationAuthorization | undefined;
};

const DomainEditor: React.FC<AuthorizationEditorProps<DomainAuthorizationConfiguration>> = (props) => {
  const { config, onAuthorize, isAuthorizing, authorizationError, authorizationType, authorization } = props;
  const { register, handleSubmit } = useForm<DomainAuthorizationConfiguration>({
    defaultValues: config,
  });

  return (
    <form className="flex flex-col space-y-4" onSubmit={handleSubmit((config) => onAuthorize(config))}>
      <div>
        <Label htmlFor="domain">Domain</Label>
        <Input placeholder="example-org" required={true} {...register("domain")} />{" "}
      </div>
      <div>
        <input type="hidden" {...register("type")} value={authorizationType} />
        <div className="flex space-x-4">
          <Input type="submit" value="Save &amp; Authorize" disabled={isAuthorizing} />
          {!isAuthorizing && (
            <AuthorizationResultBadge authorizationError={authorizationError} authorization={authorization} />
          )}
        </div>
      </div>
    </form>
  );
};

const DomainSelectionEditor: React.FC<
  AuthorizationEditorProps<DomainAuthorizationConfiguration> & { authorizationDomains: string[] }
> = (props) => {
  const {
    config,
    onAuthorize,
    isAuthorizing,
    authorizationError,
    authorizationType,
    authorization,
    authorizationDomains,
  } = props;
  const { register, handleSubmit } = useForm<DomainAuthorizationConfiguration>({
    defaultValues: config,
  });

  return (
    <form className="flex flex-col space-y-4" onSubmit={handleSubmit((config) => onAuthorize(config))}>
      <div>
        <Label htmlFor="domain">Domain</Label>
        <Select required={true} {...register("domain")}>
          {authorizationDomains.map((domain) => (
            <option key={domain} value={domain}>
              {domain}
            </option>
          ))}
        </Select>
      </div>

      <div>
        <input type="hidden" {...register("type")} value={authorizationType} />
        <div className="flex space-x-4">
          <Input type="submit" value="Save &amp; Authorize" disabled={isAuthorizing} />
          {!isAuthorizing && (
            <AuthorizationResultBadge authorizationError={authorizationError} authorization={authorization} />
          )}
        </div>
      </div>
    </form>
  );
};

const ClientCredentialsEditor: React.FC<AuthorizationEditorProps<OAuthClientCredentialsConfiguration>> = (props) => {
  const { config, onAuthorize, isAuthorizing, authorizationError, authorizationType, authorization } = props;
  const { register, handleSubmit } = useForm<OAuthClientCredentialsConfiguration>({
    defaultValues: config,
  });

  return (
    <form className="flex flex-col space-y-4" onSubmit={handleSubmit((config) => onAuthorize(config))}>
      <div>
        <Label htmlFor="accessTokenUrl">Access Token URL</Label>
        <Input placeholder="https://example.com/ouath/token" required={true} {...register("accessTokenUrl")} />
      </div>

      <div>
        <Label htmlFor="clientId">Client ID</Label>
        <Input placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx" required={true} {...register("clientId")} />
      </div>

      <div>
        <Label htmlFor="clientSecret">Client Secret</Label>
        <Input required={true} {...register("clientSecret")} />
      </div>

      <div>
        <Label htmlFor="scope">Scope</Label>
        <Input placeholder="openid offline_access" {...register("scope")} />
      </div>

      <div>
        <input type="hidden" {...register("type")} value={authorizationType} />
        <div className="flex space-x-4">
          <Input type="submit" value="Save &amp; Authorize" disabled={isAuthorizing} />
          {!isAuthorizing && (
            <AuthorizationResultBadge authorizationError={authorizationError} authorization={authorization} />
          )}
        </div>
      </div>
    </form>
  );
};

const AuthorizationCodeEditor: React.FC<AuthorizationEditorProps<OAuthAuthorizationCodeConfiguration>> = (props) => {
  const { config, onAuthorize, isAuthorizing, authorizationError, authorizationType, authorization } = props;
  const { register, handleSubmit } = useForm<OAuthAuthorizationCodeConfiguration>({
    defaultValues: config,
  });

  return (
    <form className="flex flex-col space-y-4" onSubmit={handleSubmit((config) => onAuthorize(config))}>
      <div>
        <Label htmlFor="authorizationUrl">Authorization URL</Label>
        <Input placeholder="https://example.com/ouath/authorize" required={true} {...register("authorizationUrl")} />
      </div>

      <div>
        <Label htmlFor="accessTokenUrl">Access Token URL</Label>
        <Input placeholder="https://example.com/ouath/token" required={true} {...register("accessTokenUrl")} />
      </div>

      <div>
        <Label htmlFor="clientId">Client ID</Label>
        <Input placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx" required={true} {...register("clientId")} />
      </div>

      <div>
        <Label htmlFor="clientSecret">Client Secret</Label>
        <Input required={true} {...register("clientSecret")} />
      </div>

      <div>
        <Label htmlFor="scope">Scope</Label>
        <Input placeholder="openid offline_access" {...register("scope")} />
      </div>

      <div>
        <input type="hidden" {...register("type")} value={authorizationType} />
        <div className="flex space-x-4">
          <Input type="submit" value="Save &amp; Authorize" disabled={isAuthorizing} />
          {!isAuthorizing && (
            <AuthorizationResultBadge authorizationError={authorizationError} authorization={authorization} />
          )}
        </div>
      </div>
    </form>
  );
};

const AuthorizationResultBadge = (props: {
  authorization: IngestIntegrationAuthorization | undefined;
  authorizationError: unknown | undefined;
}) => {
  const { authorization, authorizationError } = props;
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const popoverContent =
    authorization || authorizationError ? (
      <div className={`m-2 p-2 rounded-lg shadow-lg bg-neutral-100 max-w-md text-sm flex flex-col space-y-2`}>
        {!!authorizationError && (
          <p className="text-red-700 bg-red-100 px-4 py-2 rounded-lg flex w-full">
            <FontAwesomeIcon icon={faExclamation} />
            <span className="ml-2">{toErrorMessage(authorizationError)}</span>
          </p>
        )}
        {!authorization ? (
          <p>Not authorized</p>
        ) : (
          <div>
            {authorization.expiry ? (
              <p>Authorized until {new Date(authorization.expiry * 1000).toLocaleString()}</p>
            ) : (
              <p>Authorized</p>
            )}
            {!!authorization.refreshToken && <p>Will be automatically refreshed</p>}
          </div>
        )}
      </div>
    ) : undefined;

  let opts: { icon: IconDefinition; color: string; label: string };
  if (authorizationError) {
    opts = { icon: faExclamation, color: "red-500", label: "Error" };
  } else if (authorization) {
    opts = { icon: faCheck, color: "sky-500", label: "Authorized" };
  } else {
    opts = { icon: faXmark, color: "gray-500", label: "Not Authorized" };
  }

  if (popoverContent) {
    return (
      <Popover isOpen={isPopoverOpen} positions={["right", "bottom", "left", "top"]} content={popoverContent}>
        <div
          onMouseOver={() => setIsPopoverOpen(true)}
          onMouseOut={() => setIsPopoverOpen(false)}
          className={`inline-block px-6 py-2 border-2 border-${opts.color} text-${opts.color} font-medium text-xs leading-tight uppercase rounded-full hover:bg-neutral-100 transition duration-150 ease-in-out cursor-default`}
        >
          <FontAwesomeIcon icon={opts.icon} />
          <span className="ml-2">{opts.label}</span>
        </div>
      </Popover>
    );
  } else {
    return (
      <div
        className={`inline-block px-6 py-2 border-2 border-${opts.color} text-${opts.color} font-medium text-xs leading-tight uppercase rounded-full hover:bg-neutral-100 transition duration-150 ease-in-out cursor-default`}
      >
        <FontAwesomeIcon icon={opts.icon} />
        <span className="ml-2">{opts.label}</span>
      </div>
    );
  }
};

export default AuthorizationConfigurationCard;

import React from "react";

// https://tailwind-elements.com/docs/standard/forms/select/
export const Select: React.FC<React.SelectHTMLAttributes<HTMLSelectElement>> = React.forwardRef<
  HTMLSelectElement,
  React.ComponentPropsWithoutRef<"select">
>(({ children, ...props }, ref) => (
  <select
    ref={ref}
    {...props}
    className={`form-select appearance-none
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    ${props.disabled ? "bg-gray-100" : "bg-white"} bg-clip-padding bg-no-repeat
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
    ${props.className || ""}}
    `}
  >
    {children}
  </select>
));

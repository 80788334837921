import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { LightButton } from "./buttons";

export const IntegrationCatalogNavButton: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = (props) => {
  const navigate = useNavigate();

  return (
    <LightButton
      {...props}
      onClick={() => {
        navigate("/catalog");
      }}
    >
      <span className="hidden md:inline mr-2">Catalogue</span>
      <FontAwesomeIcon icon={faList} />
    </LightButton>
  );
};

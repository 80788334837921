import { AxiosError } from "axios";

export function isAxiosError(error: unknown): error is AxiosError {
  return (error as AxiosError | undefined | null)?.isAxiosError === true;
}

export function isNotFoundError(error: unknown): boolean {
  return isAxiosError(error) && error.response?.status === 404;
}

export function isUnauthorizedError(error: unknown): boolean {
  return isAxiosError(error) && error.response?.status === 401;
}

export function toErrorMessage(error: unknown): string {
  if (isAxiosError(error)) {
    const response = error.response;
    const data = response?.data;
    return (
      (data as { message?: string }).message ??
      (data as { error?: string }).error ??
      response?.statusText ??
      error.message ??
      error.name
    );
  } else if (error instanceof Error) {
    return error.message;
  } else if (typeof error === "string") {
    return error;
  } else {
    return "Unexpected error";
  }
}

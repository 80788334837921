import { IngestConnectorDeploymentAuthorization } from "@aveni-ingest/connectors-config";

import axios, { AxiosInstance } from "axios";

export interface GenesysOrganizationInfo {
  environment: string;
  premiumAppIsAvailable: boolean;
  organization: GenesysOrganization;
  queues: GenesysQueue[];
  integrationTypes: GenesysIntegrationType[];
}

export interface GenesysOrganization {
  id: string;
  name: string;
}

export interface GenesysQueue {
  id: string;
  name: string;
  division: {
    id: string;
    name: string;
  };
}

export interface GenesysIntegrationType {
  id: string;
  name: string;
  description: string;
  provider: string;
  category: string;
}

export async function fetchOrganizationInfoAsync(
  environment: string,
  premiumAppIntegrationTypeId: string,
  authorization: IngestConnectorDeploymentAuthorization,
): Promise<GenesysOrganizationInfo> {
  const { accessToken } = authorization;

  // the reason we're doing this manually with axios is because the purecloud client
  // can't be used in the browser without ejecting from create-react-app
  // which seems like overkill for a few REST calls
  // (https://developer.genesys.cloud/forum/t/typescript-library-import-not-working/11381)
  const client = axios.create({
    baseURL: `https://api.${environment}/api/v2/`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  const organizationRequest = fetchOrganizationAsync(client);
  const queuesRequest = fetchQueuesAsync(client);
  const integrationTypesRequest = fetchIntegrationTypes(client);

  const results = {
    organization: await organizationRequest,
    queues: await queuesRequest,
    integrationTypes: await integrationTypesRequest,
  };

  // EA 2022-10-26 / AD-1651 scan the list of integrations
  // Jerome (Genesys) advised that polling for a specific integration will return 200 regardless of whether it's available
  // whereas the list of integrations will reflect the user's entitlement correctly so we just scan instead.
  // (Alternatively it can be discovered from the users/me endpoint)
  const premiumAppIsAvailable =
    results.integrationTypes.find((it) => it.id === premiumAppIntegrationTypeId) !== undefined;

  return {
    environment,
    premiumAppIsAvailable,
    ...results,
  };
}

async function fetchOrganizationAsync(client: AxiosInstance): Promise<GenesysOrganization> {
  const result = await client.get<GenesysOrganization>("organizations/me");
  return result.data;
}

async function fetchQueuesAsync(client: AxiosInstance): Promise<GenesysQueue[]> {
  const queues: GenesysQueue[] = [];
  for (let pageNumber = 1, lastPage = false; !lastPage; pageNumber++) {
    const result = await client.get<GenesysEntityPage<GenesysQueue>>(
      `routing/queues?pageNumber=${pageNumber}&pageSize=100`,
    );
    const page = result.data;
    page.entities?.forEach((queue) => queues.push(queue));
    lastPage = page.pageNumber === page.pageCount;
  }

  return queues;
}

async function fetchIntegrationTypes(client: AxiosInstance): Promise<GenesysIntegrationType[]> {
  const integrationTypes: GenesysIntegrationType[] = [];
  for (let pageNumber = 1, lastPage = false; !lastPage; pageNumber++) {
    const result = await client.get<GenesysEntityPage<GenesysIntegrationType>>(
      `integrations/types?pageNumber=${pageNumber}&pageSize=100`,
    );
    const page = result.data;
    page.entities?.forEach((integrationType) => integrationTypes.push(integrationType));
    lastPage = page.pageNumber === page.pageCount;
  }

  return integrationTypes;
}

interface GenesysEntityPage<T> {
  entities?: T[];
  pageCount: number;
  pageNumber: number;
}

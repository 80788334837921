import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TimeAgo from "javascript-time-ago";
import { useState } from "react";
import { Popover } from "react-tiny-popover";
import { IngestIntegrationDeploymentStatus } from "../../../../model";
import { integrationStatusPresentation } from "../../utils/status-presentation";
import { DeploymentResultModalDialog } from "./DeploymentResultModalDialog";

export type DeploymentStatusBadgeProps = {
  status: IngestIntegrationDeploymentStatus;
};

export const DeploymentStatusBadge: React.FC<DeploymentStatusBadgeProps> = (props) => {
  const { status } = props;
  const { message, lastUpdatedTimestamp, result } = status;
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const timeAgo = new TimeAgo("en-GB");
  const opts = integrationStatusPresentation(status);

  const popoverContent = (
    <div className={`m-2 p-2 rounded-lg shadow-lg bg-neutral-100 max-w-md text-sm`}>
      {!!message && <p className="text-sm">{message}</p>}
      <p className="italic font-light text-xs text-right">{timeAgo.format(new Date(lastUpdatedTimestamp).getTime())}</p>
    </div>
  );

  return (
    <>
      <Popover
        isOpen={isPopoverOpen}
        positions={["right", "bottom", "left", "top"]} // preferred positions by priority
        content={popoverContent}
      >
        <button
          {...props}
          onMouseOver={() => setIsPopoverOpen(true)}
          onMouseOut={() => setIsPopoverOpen(false)}
          data-bs-toggle={status.result ? "modal" : undefined}
          data-bs-target="#deploymentResultModal"
          className={
            opts.disabled
              ? `inline-block px-6 py-2 border-2 ${opts.borderColor} ${opts.textColor} font-medium text-xs leading-tight uppercase rounded-full shadow-md focus:outline-none focus:ring-0 transition duration-150 ease-in-out opacity-60 cursor-default`
              : `inline-block px-6 py-2 border-2 ${opts.borderColor} ${opts.textColor} font-medium text-xs leading-tight uppercase rounded-full hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out`
          }
        >
          <div className="flex">
            <div className="mr-2" aria-hidden="true">
              <FontAwesomeIcon icon={opts.icon} className="text-medium" />
            </div>
            <div className="grow">{opts.label}</div>
          </div>
        </button>
      </Popover>
      {!!result && <DeploymentResultModalDialog result={result} id="deploymentResultModal" />}
    </>
  );
};

import ReactMarkdown from "react-markdown";
import acwMarkdownUrl from "../../assets/catalog/genesys/docs/acw.md";
import outboundCallsMarkdownUrl from "../../assets/catalog/genesys/docs/outbound-calls.md";
import recordingMarkdownUrl from "../../assets/catalog/genesys/docs/recording.md";
import { useMarkdown } from "../../hooks";

export type GenesysHelpCardProps = {
  startExpanded?: boolean;
};

export const GenesysHelpCard: React.FC<GenesysHelpCardProps> = (props) => {
  const { data: recordingMarkdown } = useMarkdown(recordingMarkdownUrl);
  const { data: outboundCallsMarkdown } = useMarkdown(outboundCallsMarkdownUrl);
  const { data: acwMarkdown } = useMarkdown(acwMarkdownUrl);

  return (
    <section className="accordion accordion-flush block p-6 mb-4 rounded-lg shadow-lg bg-white">
      <h2 className="text-gray-900 text-lg leading-tight font-medium mb-2">Additional Info and Setup Instructions</h2>{" "}
      <article className="accordion-item border-t-0 border-l-0 border-r-0 rounded-none bg-white border border-gray-200">
        <h3 className="accordion-header mb-0" id="recordingDocsHeading">
          <button
            className={props.startExpanded ? accordionButtonStyle : accordionButtonStyle + " collapsed"}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#recordingDocs"
            aria-expanded="false"
            aria-controls="recordingDocs"
          >
            Setting up Recording
          </button>
        </h3>
        <div
          id="recordingDocs"
          className={props.startExpanded ? accordionDocsStyle + " show" : accordionDocsStyle}
          aria-labelledby="recordingDocsHeading"
        >
          <ReactMarkdown className="accordion-body pb-4 text-gray-700 font-light prose mt-4">
            {recordingMarkdown ?? ""}
          </ReactMarkdown>
        </div>
      </article>
      <article className="accordion-item border-l-0 border-r-0 rounded-none bg-white border border-gray-200">
        <h3 className="accordion-header mb-0" id="outboundCallsDocsHeading">
          <button
            className={accordionButtonStyle + " collapsed"}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#outboundCallsDocs"
            aria-expanded="false"
            aria-controls="outboundCallsDocs"
          >
            Outbound Calls
          </button>
        </h3>
        <div id="outboundCallsDocs" className={accordionDocsStyle} aria-labelledby="outboundCallsDocsHeading">
          <ReactMarkdown className="accordion-body pb-4 text-gray-700 font-light prose mt-4">
            {outboundCallsMarkdown ?? ""}
          </ReactMarkdown>
        </div>
      </article>
      <article className="accordion-item border-l-0 border-r-0 border-b-0 rounded-none bg-white border border-gray-200">
        <h3 className="accordion-header mb-0" id="acwDocsHeading">
          <button
            className={accordionButtonStyle + " collapsed"}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#acwDocs"
            aria-expanded="false"
            aria-controls="acwDocs"
          >
            After-Call Work
          </button>
        </h3>
        <div id="acwDocs" className={accordionDocsStyle} aria-labelledby="acwDocsHeading">
          <ReactMarkdown className="accordion-body text-gray-700 font-light prose mt-4">
            {acwMarkdown ?? ""}
          </ReactMarkdown>
        </div>
      </article>
    </section>
  );
};

// from https://tailwind-elements.com/docs/standard/components/accordion/

const accordionButtonStyle = `accordion-button
relative
flex
items-center
w-full
py-4
text-base text-gray-800 text-left
bg-white
border-0
rounded-none
transition
focus:outline-none`;

const accordionDocsStyle = "accordion-collapse border-0 collapse";

import React from "react";

export const Label: React.FC<React.LabelHTMLAttributes<HTMLLabelElement>> = React.forwardRef<
  HTMLLabelElement,
  React.ComponentPropsWithoutRef<"label">
>(({ children, ...props }, ref) => (
  <label
    ref={ref}
    {...props}
    className={`form-label inline-block mb-2 text-gray-700 ${props.className || ""}}
`}
  >
    {children}
  </label>
));

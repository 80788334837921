import { faWarning } from "@fortawesome/free-solid-svg-icons/faWarning";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export const WarningAlert: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <div className="bg-yellow-100 rounded-lg py-5 px-6 mb-3 text-yellow-700 flex w-full" role="alert">
      <div className="mr-4 text-lg fill-current align-baseline " aria-hidden="true">
        <FontAwesomeIcon icon={faWarning} />
      </div>
      <div className="grow">{children}</div>
    </div>
  );
};

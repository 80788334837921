import { Auth0Provider } from "@auth0/auth0-react";
import "@fontsource/poppins/latin.css";
import "tw-elements";

import ReactDOM from "react-dom/client";
import App from "./App";
import config from "./config";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  // <React.StrictMode>
  <Auth0Provider
    domain={config.auth.domain!}
    clientId={config.auth.clientId!}
    audience={config.auth.audience}
    scope={config.auth.scope}
    redirectUri={window.location.origin}
  >
    <App />
  </Auth0Provider>,
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

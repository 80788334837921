import axios from "axios";
import _ from "lodash";
import {
  AuthorizationRedirect,
  CreateIngestIntegrationOptions,
  DeleteIngestIntegrationOptions,
  IngestConnectorInfo,
  IngestIntegration,
  IngestIntegrationDeploymentStatus,
  IngestIntegrationsList,
  UpdateIngestIntegrationOptions,
} from "../../model";
import config from "./config";

const api = axios.create({
  baseURL: config.api.url,
});

export function setAccessToken(accessToken: string) {
  api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
}

export async function authorizeIngestIntegration(
  integrationId: string,
  returnUrl?: string,
  refresh?: boolean,
): Promise<AuthorizationRedirect> {
  try {
    return api
      .get<AuthorizationRedirect>(`/integrations/${integrationId}/authorize`, {
        params: {
          returnUrl,
          refresh,
        },
      })
      .then((v) => v.data);
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export function getIngestConnectors() {
  return api.get<IngestConnectorInfo[]>("/connectors").then((v) => v.data);
}

export async function getIngestIntegrations() {
  const maxResults = 25;
  const results: IngestIntegration[] = [];
  let nextPageToken: string | undefined;
  do {
    const uri = nextPageToken
      ? `/integrations?maxResults=${maxResults}&nextPageToken=${encodeURIComponent(nextPageToken)}`
      : `/integrations?maxResults=${maxResults}`;

    const page = await api.get<IngestIntegrationsList>(uri).then((v) => v.data);
    results.push(...page.items);
    nextPageToken = page.nextPageToken;
  } while (nextPageToken);

  return _.chain(results).sortBy("integrationId").sortBy("name").value();
}

export function getIngestIntegration(integrationId: string) {
  return api.get<IngestIntegration>("/integrations/" + integrationId).then((v) => v.data);
}

export function createIngestIntegration(options: CreateIngestIntegrationOptions) {
  return api.post<IngestIntegration>("/integrations", options).then((v) => v.data);
}

export function updateIngestIntegration(integrationId: string, options: UpdateIngestIntegrationOptions) {
  return api.put<IngestIntegration>("/integrations/" + integrationId, options).then((v) => v.data);
}

export function deleteIngestIntegration(integrationId: string, options: DeleteIngestIntegrationOptions) {
  return api
    .request({
      method: "DELETE",
      url: "/integrations/" + integrationId,
      data: options,
    })
    .then((v) => v.data);
}

export function getDeploymentStatus(trackingToken: string) {
  return api.get<IngestIntegrationDeploymentStatus | null>("/deployments/" + trackingToken).then((v) => v.data);
}

import { faCirclePlus, faExternalLink } from "@fortawesome/free-solid-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import genesysIcon from "../../assets/catalog/genesys/icon.png";
import aveniLogo from "../../assets/images/aveni.png";

/**
 * Help documentation linked from Genesys AppFoundry.
 */
const GenesysAppFoundryHelpPage = () => {
  const APPFOUNDRY_URL =
    "https://appfoundry.mypurecloud.com/filter/genesyscloud/listing/3775d95b-303b-49f5-936e-e9ed1080bb0d";

  useEffect(() => {
    window.document.title = "Connecting Genesys Cloud CX to Aveni Detect";
  }, []);

  return (
    <div className="max-w-screen-lg m-auto px-4 py-8 flex flex-col space-y-4">
      <div className="p-6 rounded-lg shadow-lg bg-white flex flex-col space-y-4">
        <div className="flex space-x-4 text-3xl h-[32px] items-center m-2 self-center">
          <img className="mb-1" src={genesysIcon} alt="Genesys Cloud CX" />
          <span className="pl-3">
            <FontAwesomeIcon icon={faArrowRight} className="text-neutral-600" />
          </span>
          <img className="h-[40px]" src={aveniLogo} alt="Aveni" />
        </div>
        <h1 className="text-3xl text-neutral-800 font-light text-center ">
          Connecting Genesys Cloud CX to Aveni Detect
        </h1>
        <article className="prose min-w-full">
          <h2>Introduction</h2>
          <p>
            <a href="https://aveni.ai/detect/">Aveni Detect</a> provides a &lsquo;Machine Line of Defence&rsquo;,
            combining the latest in machine learning with human intelligence to analyse every customer interaction.
          </p>
          <p>
            With the Genesys Cloud integration, calls are brought into <AveniDetect /> automatically from your Genesys
            Cloud CX organisation. Our AI-driven platform identifies the highest value interactions needing attention,
            while automating much of the QA assessment process using Natural Language Processing.
          </p>
          <p>
            <AveniDetect /> is purchased and billed through your Genesys Cloud CX subscription. We offer a metered
            billing model, where you pay based on the total duration of the calls we analyse for you, subject to a
            minimum volume per month. To discuss pricing and get <AveniDetect /> enabled for your Genesys Cloud
            organisation, locate{" "}
            <a href={APPFOUNDRY_URL} target="_blank" rel="noreferrer">
              Aveni Detect on the Genesys AppFoundry store
              <FontAwesomeIcon className="pl-2" icon={faExternalLink} />
            </a>{" "}
            and click the{" "}
            <span className="rounded-xl text-white border-orange-600 border-1 bg-orange-600 px-4 p1-2 whitespace-nowrap">
              Contact Us
            </span>{" "}
            button &mdash; or reach out directly to <a href="mailto:info@aveni.ai">info@aveni.ai</a>.
            <br />
          </p>
          <p className="italic">
            If you are working with a managed service provider, you may need to ask them to enable the app for you.
          </p>
          <h2>Enabling the Integration</h2>
          <p>
            Once you have purchased <AveniDetect /> on the AppFoundry store, it will be available to your System
            Administrator in the <strong>Admin &gt; Integrations</strong> control panel, by clicking the
            <span className="border-2 px-2 py-1 mx-2 bg-neutral-100 text-sm">
              <FontAwesomeIcon icon={faCirclePlus} className="pr-2" />
              Integrations
            </span>
            button.
          </p>
          <ol>
            <li>
              <p>
                Click the <strong>Install</strong> link on the app, and choose a suitable name &mdash; such as{" "}
                <AveniDetect />.
              </p>
            </li>
            <li>
              <p>
                In the <strong>Permissions</strong> panel, click the
                <span className="border-2 px-2 py-1 mx-2 bg-neutral-100 text-blue-700 text-sm">
                  <FontAwesomeIcon icon={faCirclePlus} className="pr-2" />
                  Add Role
                </span>
                button and choose the <em>admin</em> role to make the installation wizard available to administrators.
              </p>
            </li>
            <li>
              <p>
                Click <span className="px-2 py-1 mx-1 bg-blue-600 text-white text-xs">Save</span> and then toggle the
                app to <span className="font-medium">Active</span> and refresh the browser window.
                <br />
                It should now appear under the <strong>Apps</strong> menu.
              </p>
            </li>
          </ol>
          <p>
            Now you should be able to click <AveniDetect /> from the <strong>Apps</strong> menu to complete the setup.
          </p>
          <h2>Completing the Setup Process</h2>
          <p>
            You will need Aveni credentials with <em>System Administrator</em> permissions &mdash; which you can verify
            by signing into{" "}
            <a href="https://integration.avenidetect.com/" target="_blank" rel="noreferrer">
              integration.avenidetect.com
            </a>
            . If you have not received an invitation, please talk to your Aveni representative or email <SupportLink />.
          </p>
          <ol>
            <li>
              <p>
                Click <AveniDetect /> from the <strong>Apps</strong> menu, and sign in using your Aveni credentials when
                prompted.
                <br />
                This will open a new tab in your browser.
              </p>
            </li>
            <li>
              <p>
                Click the <span className="px-2 py-1 mx-1 bg-blue-600 uppercase text-white text-xs">Create</span> button
                to create the integration.
              </p>
            </li>
            <li>
              <p>
                When prompted, click the{" "}
                <span className="px-2 py-1 mx-1 bg-blue-600 uppercase text-white text-xs">Authorise</span> button to
                allow Aveni to access your Genesys Cloud organisation.
                <br />
                Occasionally you may need to press this twice.
              </p>
            </li>
            <li>
              <p>
                Review and update the list of Genesys Queues from which Aveni will automatically import calls.
                <br />
                If you are conducting a limited pilot, you may want to choose a subset of queues and add more later.
              </p>
            </li>
            <li>
              <p>
                Click <span className="px-2 py-1 mx-1 bg-blue-600 uppercase text-white text-xs">Activate</span> to turn
                on the integration. Review additional instructions shown on the page to make sure you have enabled
                stereo call recording for your Genesys Cloud organisation.
              </p>
            </li>
          </ol>
          <p>
            If you have difficulty setting this up, you can completely remove the integration and start again by
            clicking <span className="text-red-500 underline">Uninstall</span>.
          </p>
          <h2>After Setup</h2>
          <p>
            You can monitor billing in the Genesys <strong>Subscription</strong> control panel, where <AveniDetect />{" "}
            charges are updated daily.
          </p>
          <p>
            To hide the <AveniDetect /> app from the <strong>Apps</strong> menu once setup is complete, go to the
            Genesys Cloud <strong>Integrations</strong> control panel and disable or remove the integration. This does
            not affect <AveniDetect /> call analysis or stop Aveni billing. If you want to stop <AveniDetect /> from
            analysing calls, sign into{" "}
            <a href="https://integration.avenidetect.com/" target="_blank" rel="noreferrer">
              integration.avenidetect.com
            </a>{" "}
            and <em>Deactivate</em> or <em>Uninstall</em> the integration.
          </p>
          <p>
            If you are managing multiple Genesys Cloud organisations, you can install the integration in each one and
            repeat the setup process. Avoid setting up multiple integrations covering the same Genesys Cloud
            organisation or you may find that calls appear more than once in <AveniDetect /> &mdash; and you will be
            billed more than once for each call.
          </p>
          <h2>User Management</h2>
          <p>
            Invite users and manage accounts in the{" "}
            <a
              href="https://avenidetect.com/users"
              className="font-medium text-aveni-blue"
              target="_blank"
              rel="noreferrer"
            >
              Aveni Detect platform settings
            </a>
            .
            <br />
            Most Aveni client organisations invite Quality Assurance, Outcome testing, Compliance, Management, Coaching
            and Training staff to Aveni in the first phase of adoption; Agents or Advisers can be added later.
          </p>
          <p>
            Aveni can configure Single Sign-On (SSO) for your business for an additional fee.
            <br />
            For more information, please talk to your Aveni representative or email <SupportLink />.
          </p>
        </article>
      </div>
    </div>
  );
};

const AveniDetect = () => <span className="font-medium text-aveni-blue">Aveni Detect</span>;

const SupportLink = () => <a href="mailto:support@aveni.ai">support@aveni.ai</a>;

export default GenesysAppFoundryHelpPage;

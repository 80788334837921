import { faArrowLeft } from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

export const IntegrationsNavLink: React.FC = () => {
  return (
    <span className="font-light text-aveni-blue">
      <FontAwesomeIcon icon={faArrowLeft} />
      <Link to="/integrations" className="underline pl-1">
        Integrations
      </Link>
    </span>
  );
};

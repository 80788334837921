import { ActivationPrompt } from "../../components/integration/ActivationPrompt";
import AuthorizationConfigurationCard from "../../components/integration/AuthorizationConfigurationCard";
import JsonConfigurationCard from "../../components/integration/JsonConfigurationCard";
import { IntegrationViewProps } from "../types";

export const FallbackIntegrationView: React.FC<IntegrationViewProps> = (props) => {
  const { integration, connector } = props;

  return (
    <>
      {(!connector.authorizationRequired || integration.authorization) && (
        <ActivationPrompt integration={integration} />
      )}

      {!!connector.authorizationType && (
        <AuthorizationConfigurationCard integration={integration} authorizationType={connector.authorizationType} />
      )}

      <JsonConfigurationCard integration={integration} connector={connector} />
    </>
  );
};

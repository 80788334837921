import { User } from "@auth0/auth0-react";
import { useQueryClient } from "@tanstack/react-query";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { IngestIntegration, IngestIntegrationAuthorization } from "../../model";
import { authorizeIngestIntegration, getIngestIntegration } from "./api";

export interface IntegrationAuthorizationContextValue {
  isAuthorizing: boolean;
  isAuthorized: boolean;
  isRefreshing: boolean;
  isExpiring: boolean;
  authorization: IngestIntegrationAuthorization | undefined;
  authorizationError: unknown | undefined;
  authorize: () => void;
}

export type IntegrationAuthorizationContextProviderProps = {
  integration: IngestIntegration;
};

const IntegrationAuthorizationContext = createContext<IntegrationAuthorizationContextValue>(
  {} as IntegrationAuthorizationContextValue,
);

export const IntegrationAuthorizationContextProvider: React.FC<
  React.PropsWithChildren<IntegrationAuthorizationContextProviderProps>
> = ({ children, ...props }) => {
  const { integration } = props;
  const integrationId = integration.integrationId;

  /**
   * Determines when the authorization will be silently refreshed (if possible), in milliseconds before the actual token expiry.
   *
   * Set to be sufficiently before the {@link EXPIRING_THRESHOLD} so that warning is not seen unless the refresh fails.
   */
  const REFRESH_THRESHOLD = 120000;

  /**
   * Determines when the {@link isExpiring} flag will be set, in milliseconds before the actual token expiry.
   */
  const EXPIRING_THRESHOLD = 90000;

  /**
   * Determines when the authorization token will be cleared, in milliseconds before the actual token expiry
   */
  const CLEAR_AUTHORIZATION_THRESHOLD = 15000;

  const [minTimeoutDelay, setMinTimeoutDelay] = useState(0);
  const [isExpiring, setIsExpiring] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isAuthorizing, setIsAuthorizing] = useState(false);
  const [authorization, setAuthorization] = useState<IngestIntegrationAuthorization | undefined>();
  const [authorizationError, setAuthorizationError] = useState<unknown | undefined>(undefined);

  const [trackedAccessToken, setTrackedAccessToken] = useState<string | undefined>(undefined);
  const [expiringTimeout, setExpiringTimeout] = useState<number | undefined>(undefined);
  const [clearAuthorizationTimeout, setClearAuthorizationTimeout] = useState<number | undefined>(undefined);
  const [refreshTimeout, setRefreshTimeout] = useState<number | undefined>(undefined);

  const queryClient = useQueryClient();

  // re-examine the authorization when it changes
  useEffect(() => {
    // local scope shadows state variables
    const integrationId = integration.integrationId;
    const authorization = integration.authorization;

    // no action required unless the access token has changed
    if (authorization && authorization.accessToken !== trackedAccessToken) {
      setTrackedAccessToken(authorization.accessToken);

      if (authorization.expiry) {
        // minimum timeout to avoid tight loops in case of a screw-up
        const timeUntilExpiry = authorization.expiry * 1000 - Date.now();

        const refreshDelay = timeUntilExpiry - REFRESH_THRESHOLD;
        const expiringDelay = timeUntilExpiry - EXPIRING_THRESHOLD;
        const clearAuthorizationDelay = timeUntilExpiry - CLEAR_AUTHORIZATION_THRESHOLD;

        // try to keep authorization refreshed in the background so that warning is never neccessary
        if (refreshTimeout) window.clearTimeout(refreshTimeout);
        setRefreshTimeout(
          window.setTimeout(() => {
            // this flag will force a re-render
            setIsRefreshing(true);

            // don't provide a returnUrl since we'll never use it
            authorizeIngestIntegration(integrationId, undefined, true)
              .then(async (ar) => {
                // we can't really follow a redirect headlessly due to CORS restrictions
                // so just abandon the authorization flow in that case
                if (!ar.redirect) {
                  // update the query cache with fresh authorization as for optimistic updates
                  await queryClient.cancelQueries(["integrations", integrationId]);
                  const update = await getIngestIntegration(integrationId);
                  queryClient.setQueryData(["integrations", integrationId], update);
                }
              })
              .catch((e) => {
                console.error("Failed to refresh authorization", e);
              })
              .finally(() => {
                queryClient.invalidateQueries(["integrations", integrationId]);
                setIsRefreshing(false);
              });
          }, Math.max(minTimeoutDelay, refreshDelay)),
        );

        // warn the user if the token is about to expire
        if (expiringTimeout) window.clearTimeout(expiringTimeout);
        if (expiringDelay > 0) {
          setIsExpiring(false);

          setExpiringTimeout(
            window.setTimeout(() => {
              setIsExpiring(true);
            }, Math.max(minTimeoutDelay, expiringDelay)),
          );
        } else {
          setIsExpiring(true);
        }

        if (clearAuthorizationTimeout) window.clearTimeout(clearAuthorizationTimeout);
        if (clearAuthorizationDelay > 0) {
          setAuthorization(integration.authorization);

          // clear the authorization when the time comes
          setClearAuthorizationTimeout(
            window.setTimeout(() => {
              setAuthorization(undefined);
              setIsExpiring(false);
            }, Math.max(minTimeoutDelay, clearAuthorizationDelay)),
          );
        } else {
          setAuthorization(undefined);
          setIsExpiring(false);
        }

        // longer delay in future to avoid tight loops
        setMinTimeoutDelay(5000);
      } else {
        setAuthorization(authorization);
        setIsExpiring(false);
      }
    }
  }, [
    queryClient,
    integration,
    trackedAccessToken,
    minTimeoutDelay,
    refreshTimeout,
    expiringTimeout,
    clearAuthorizationTimeout,
  ]);

  const value = useMemo<IntegrationAuthorizationContextValue>(() => {
    const refetch = async () => {
      await queryClient.cancelQueries(["integrations", integrationId]);
      const update = await getIngestIntegration(integrationId);
      queryClient.setQueryData(["integrations", integrationId], update);
    };

    const authorize = () => {
      setIsAuthorizing(true);
      authorizeIngestIntegration(integrationId, window.location.href)
        .then(async (ar) => {
          setAuthorizationError(undefined);
          if (ar.redirect) {
            window.location.href = ar.redirect;
            // NB: don't reset isAuthorizing here because this will cause any Authorize prompt to reappear
            // while we're waiting for the above redirect to complete
          } else {
            await refetch();
          }
        })
        .catch(async (e) => {
          await refetch();
          setIsAuthorizing(false);
          setAuthorizationError(e);
        })
        .finally(() => {
          queryClient.invalidateQueries(["integrations", integrationId]);
        });
    };

    return {
      authorization,
      authorizationError,
      isAuthorized: !!authorization,
      isExpiring,
      isRefreshing,
      isAuthorizing,
      authorize,
    };
  }, [queryClient, integrationId, isAuthorizing, isRefreshing, isExpiring, authorization, authorizationError]);

  return <IntegrationAuthorizationContext.Provider value={value}>{children}</IntegrationAuthorizationContext.Provider>;
};

export function claimCheck(claims?: User): boolean {
  const roleClaims = (claims?.["https://avenidetect.com/roles"] as string[] | undefined) ?? [];
  return roleClaims.includes("System Administrator");
}

export const useIntegrationAuthorization = () => {
  return useContext(IntegrationAuthorizationContext);
};

import { GenesysConfig } from "@aveni-ingest/connectors-config/connectors/genesys";
import { useEffect, useState } from "react";
import { PulseLoader } from "react-spinners";
import { useIntegrationAuthorization } from "../../auth";
import { ActivationPrompt } from "../../components/integration/ActivationPrompt";
import { isUnauthorizedError } from "../../errors";
import { useUpdateIntegration } from "../../hooks";
import { IntegrationViewProps } from "../types";
import { GenesysAuthorizeCard } from "./GenesysAuthorizeCard";
import { GenesysConfigCard } from "./GenesysConfigCard";
import { GenesysHelpCard } from "./GenesysHelpCard";
import { GenesysOrgInfoError } from "./GenesysOrgInfoError";
import { useOrganizationInfo } from "./hooks";

export type GenesysIntegrationViewProps = IntegrationViewProps;

export const GenesysIntegrationView: React.FC<GenesysIntegrationViewProps> = (props) => {
  const { integration, connector } = props;
  const { integrationId } = integration;

  const [firstUse] = useState(integration.trackingToken === undefined);
  const { mutate: updateIntegration } = useUpdateIntegration(integrationId);
  const { isAuthorized } = useIntegrationAuthorization();

  const configuration = integration.configuration as GenesysConfig | undefined;

  // Checking for the integration type ensures that the customer purchased the subscription
  // See https://developer.genesys.cloud/appfoundry/premium-app-wizard/#purchasing
  const premiumAppIntegrationTypeId = connector.properties?.["premiumAppIntegrationTypeId"] as string | undefined;
  if (!premiumAppIntegrationTypeId) throw new Error("Missing premiumAppIntegrationTypeId in connector properties");

  const {
    data: orgInfo,
    isLoading: isOrgInfoLoading,
    error: orgInfoError,
  } = useOrganizationInfo(integrationId, premiumAppIntegrationTypeId, configuration?.environment);

  // init configuration from orgInfo
  useEffect(() => {
    const configuration = integration?.configuration as Partial<GenesysConfig> | undefined;
    if (configuration && (!configuration.organizationId || !configuration.queueIds) && orgInfo) {
      // update the name unless it's been changed by the user
      const name = integration.name !== "Genesys" ? integration.name : `Genesys — ${orgInfo.organization.name}`;

      configuration.organizationId = orgInfo.organization.id;
      configuration.queueIds = orgInfo.queues.map((q) => q.id);
      configuration.appFoundry = orgInfo.premiumAppIsAvailable;
      updateIntegration({ name, configuration });
    }
  }, [integration, orgInfo, updateIntegration]);

  return (
    <>
      {isAuthorized && orgInfo && (
        <ActivationPrompt
          integration={integration}
          active={
            <span>
              Analysing recordings from Genesys Cloud CX organisation (
              <strong className="whitespace-nowrap">{orgInfo.organization.name}</strong>)
            </span>
          }
          activePending={
            <span>
              Activating or updating integration to your Genesys Cloud CX organisation (
              <strong className="whitespace-nowrap">{orgInfo.organization.name}</strong>)
            </span>
          }
          inactive={
            <span>
              Activate to start analysing calls from your Genesys Cloud CX organisation (
              <strong className="whitespace-nowrap">{orgInfo.organization.name}</strong>)
            </span>
          }
          inactivePending={
            <span>
              Deactivating integration to Genesys Cloud CX organisation (
              <strong className="whitespace-nowrap">{orgInfo.organization.name}</strong>)
            </span>
          }
        />
      )}

      {integration.trackingToken && <GenesysHelpCard startExpanded={firstUse} />}

      {!isAuthorized || isUnauthorizedError(orgInfoError) ? (
        <GenesysAuthorizeCard integration={integration} connector={connector} />
      ) : isOrgInfoLoading ? (
        <PulseLoader color="gray" size="10px" />
      ) : orgInfoError ? (
        <GenesysOrgInfoError error={orgInfoError} />
      ) : orgInfo ? (
        <GenesysConfigCard integration={integration} orgInfo={orgInfo} />
      ) : undefined}
    </>
  );
};

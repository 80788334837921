import { faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import genesysIcon from "../../assets/catalog/genesys/icon.png";
import aveniLogo from "../../assets/images/aveni.png";
import { ErrorAlert } from "../../components/alerts/ErrorAlert";
import { PrimaryButton } from "../../components/buttons";
import { toErrorMessage } from "../../errors";
import { useCreateIntegration } from "../../hooks";

const GenesysSetupPage = () => {
  const [searchParams] = useSearchParams();
  const environment = searchParams.get("environment");

  const { mutate: createIntegration, error, isLoading: isCreating, data: integration } = useCreateIntegration();

  const onCreate = () => {
    createIntegration({
      name: "Genesys",
      connectorId: "genesys",
      configuration: {
        environment,
      },
      authorizationConfiguration: {
        type: "domain",
        domain: environment!,
      },
    });
  };

  useEffect(() => {
    window.document.title = "Ready to connect Genesys Cloud CX to Aveni Detect";
  }, []);

  if (!environment) {
    return (
      <div className="flex h-screen">
        <div className="m-auto">
          <ErrorAlert>
            <p className="font-medium text-lg mb-2">Setup Problem</p>
            <p>Sorry, we're not sure which Genesys Cloud CX region you're using.</p>
            <p>Please try again starting from the Genesys Admin console.</p>
            <p>If this problem persists, please contact your Aveni representative for help.</p>
          </ErrorAlert>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex h-screen">
        <div className="m-auto">
          <ErrorAlert>
            <p className="font-medium text-lg mb-2">Setup Problem</p>
            <p>Sorry, we can't seem to create the Integration right now.</p>
            <p>If this problem persists, please contact your Aveni representative for help.</p>
            <p className="mt-2 text-sm opacity-50 ">{toErrorMessage(error)}</p>
          </ErrorAlert>
        </div>
      </div>
    );
  }

  if (integration) {
    return <Navigate to={`/integrations/${encodeURIComponent(integration.integrationId)}`} replace={true} />;
  }

  // originally this was just a transient progress indicator and the creation was done in useEffect
  // but since strict-mode does a double-render this was causing the creation to happen twice
  if (isCreating) {
    return (
      <div className="flex h-screen">
        <div className="m-auto  p-6 rounded-lg shadow-lg bg-white flex flex-col space-y-4">
          <div className="flex space-x-4 text-3xl h-[32px] items-center m-2 self-center">
            <img className="mb-1" src={genesysIcon} alt="Genesys Cloud CX" />
            <span className="pl-3">
              <PulseLoader color="#2563EB" size="10px" />
            </span>
            <img className="h-[40px]" src={aveniLogo} alt="Aveni" />
          </div>
          <p className="text-center font-light">Creating the Integration</p>
          <div className="block self-center">
            <PrimaryButton disabled={true}>Create</PrimaryButton>
          </div>
        </div>
      </div>
    );
  } else {
    // arrow is padded to 42px as a hack to stop it moving when it's replaced with a PulseLoader
    // (probably not very robust, but definitely good enough for the purpose)
    return (
      <div className="flex h-screen">
        <div className="m-auto  p-6 rounded-lg shadow-lg bg-white flex flex-col space-y-4">
          <div className="flex space-x-4 text-3xl h-[32px] items-center m-2 self-center">
            <img className="mb-1" src={genesysIcon} alt="Genesys Cloud CX" />
            <span className="pl-3">
              <FontAwesomeIcon icon={faArrowRight} className="text-neutral-600 animate-pulse w-[42px]" />
            </span>
            <img className="h-[40px]" src={aveniLogo} alt="Aveni" />
          </div>
          <p className="text-center font-light">Ready to create the integration</p>
          <div className="block self-center">
            <PrimaryButton onClick={onCreate}>Create</PrimaryButton>
          </div>
        </div>
      </div>
    );
  }
};

export default GenesysSetupPage;

import { faExternalLink } from "@fortawesome/free-solid-svg-icons/faExternalLink";
import { faLink } from "@fortawesome/free-solid-svg-icons/faLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useNavigate } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import { IngestConnectorInfo } from "../../../model";
import { iconUrl } from "../assets/catalog";
import { ErrorAlert } from "../components/alerts/ErrorAlert";
import { LightButton, PrimaryButton } from "../components/buttons";
import { IntegrationsNavLink } from "../components/IntegrationsNavLink";
import { useConnectorDescription, useConnectors, useCreateIntegration } from "../hooks";

const CatalogPage = () => {
  const { data: connectors, isLoading, error } = useConnectors();
  const [moreDetailConnectorId, setMoreDetailConnectorId] = useState<string | undefined>(undefined);

  useEffect(() => {
    window.document.title = "Aveni Integration Catalogue";
  }, []);

  return (
    <>
      <div className="max-w-screen-lg m-auto px-4 py-8 flex flex-col space-y-4">
        <div>
          <h1 className="text-3xl text-aveni-blue font-light leading-[1.5]">
            Integration Catalogue
            {isLoading && (
              <span className="ml-4">
                <PulseLoader color="gray" size="10px" />
              </span>
            )}
          </h1>
        </div>

        {!!error && <ConnectorsErrorAlert error={error} />}

        {!!connectors && (
          <p className="text-neutral-600 text-lg">
            <span className="font-medium">
              {connectors.length} {connectors.length === 1 ? "integration" : "integrations"}
            </span>
            <span className="font-light"> available for install</span>
          </p>
        )}

        <IntegrationsNavLink />

        {!!connectors &&
          connectors.map((connector) => (
            <ConnectorCard
              key={connector.connectorId}
              connector={connector}
              isMoreDetail={moreDetailConnectorId === connector.connectorId}
              onMoreDetail={() => {
                setMoreDetailConnectorId(connector.connectorId);
              }}
            />
          ))}
      </div>
    </>
  );
};

const ConnectorsErrorAlert = (props: { error: unknown }) => {
  return (
    <ErrorAlert>
      <p className="font-medium text-lg mb-2">Service Problem</p>
      <p>Sorry, we can't seem to load the Integration catalogue right now.</p>
      <p>If this problem persists, please contact Aveni support.</p>
    </ErrorAlert>
  );
};

const ConnectorCard = (props: { connector: IngestConnectorInfo; isMoreDetail: boolean; onMoreDetail: () => void }) => {
  const { connector, isMoreDetail, onMoreDetail } = props;

  const { data: detail, isLoading: detailIsLoading } = useConnectorDescription(connector.connectorId, {
    enabled: isMoreDetail,
  });

  const navigate = useNavigate();
  const { mutateAsync: create, isLoading: isCreating } = useCreateIntegration();
  const install = () => {
    if (connector.installUrl) {
      window.location.href = connector.installUrl;
    } else {
      create({
        // TODO we could consider giving each one a unique name - but we'll need to access the list of integrations to do that
        connectorId: connector.connectorId,
        name: connector.label,
        configuration: {},
      }).then((integration) => navigate(`/integrations/${integration.integrationId}`));
    }
  };

  const InstallButton = (
    <PrimaryButton disabled={isCreating} onClick={install}>
      <span>Install</span>
      {!!connector.installUrl && (
        <span className="ml-2">
          <FontAwesomeIcon icon={faExternalLink} />
        </span>
      )}
    </PrimaryButton>
  );

  return (
    <div className="p-6 mb-4 rounded-lg shadow-lg bg-white flex">
      <div className="hidden md:flex md:w-44 bg-transparent border-r-neutral-200 border-r-2 pr-6 mr-6  flex-col justify-center items-center space-y-2">
        <div className="flex-grow">
          <ConnectorIcon connectorId={connector.connectorId} />
        </div>
        <div>{InstallButton}</div>
      </div>
      <div className="w-full">
        <h2 className="text-gray-900 text-lg leading-tight font-medium mb-2">
          {connector.label}
          {isCreating && (
            <span className="ml-2">
              <PulseLoader color="#2563EB" size="10px" />
            </span>
          )}
        </h2>
        <p className="text-gray-700 text-base flex flex-col space-y-2">{connector.description}</p>
        {isMoreDetail && detail && (
          <article className="text-gray-700 font-light prose mt-4">
            <ReactMarkdown>{detail}</ReactMarkdown>
          </article>
        )}
        <div className="flex mt-4">
          <div className="md:hidden mr-2">{InstallButton}</div>
          {(!isMoreDetail || detailIsLoading) && (
            <div className="mr-2">
              <LightButton onClick={onMoreDetail} disabled={isMoreDetail}>
                More detail
              </LightButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const ConnectorIcon = (props: { connectorId: string }) => {
  const iconSrc = iconUrl(props.connectorId);
  const [iconUnavailable, setIconUnavailable] = useState(!iconSrc);

  return (
    <>
      {!!iconSrc && (
        <img
          src={iconSrc}
          onError={() => {
            // nasty hack
            // As with description.md this is actually failing due to being served HTML from public/index.html
            // not because of a 404 error as originally anticipated.
            setIconUnavailable(true);
          }}
          alt=""
          loading="lazy"
          aria-hidden
        />
      )}
      {iconUnavailable && <FontAwesomeIcon icon={faLink} className="text-neutral-300 text-5xl" />}
    </>
  );
};

export default CatalogPage;

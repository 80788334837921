import { toErrorMessage } from "../../errors";
import { ErrorAlert } from "./ErrorAlert";

export type AuthorizationErrorAlertProps = {
  error: unknown;
};

export const AuthorizationErrorAlert: React.FC<AuthorizationErrorAlertProps> = (props) => {
  return (
    <ErrorAlert>
      <p className="font-medium text-lg mb-2">Authorisation Error</p>
      <p>{`${toErrorMessage(props.error)}`}</p>
    </ErrorAlert>
  );
};

import * as apikey from "./dataintg-apikey";
import * as webhook from "./dataintg-webhook";
import * as genesys from "./genesys";

const assets: Record<string, ConnectorAssets | undefined> = {
  genesys,
  "dataintg-apikey": apikey,
  "dataintg-webhook": webhook,
};

interface ConnectorAssets {
  icon: string;
  description: string;
}

export function iconUrl(connectorId: string): string | undefined {
  return assets[connectorId]?.icon;
}

export function descriptionUrl(connectorId: string): string | undefined {
  return assets[connectorId]?.description;
}

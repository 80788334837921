import { faCloudDownload } from "@fortawesome/free-solid-svg-icons/faCloudDownload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as uuid from "uuid";
import config from "../../config";

export type DataIntgWebhookInfoCardProps = { url?: string };

// prettier-ignore
export const DataIntgWebhookInfoCard: React.FC<DataIntgWebhookInfoCardProps> = (props) => {
  const url = props.url ?? "https://example.com/webhook";

  let host: string = "example.com";
  try {
    const parsed = new URL(url);
    host = parsed.host;
  } catch (e) {}

  return (
    <section className="block p-6 mb-6 rounded-lg shadow-lg bg-white">
      <h2 className="text-gray-900 text-lg leading-tight font-medium mb-2">Implementing the Webhook Endpoint</h2>
      <article className="text-gray-700 prose max-w-none">
        <p>
          Aveni will make a POST request to the Webhook URL, like this:
        </p>
        <pre className="w-full">
          <code>
{`POST ${url} HTTP/1.1
accept: application/json, text/plain, */*
accept-encoding: gzip, compress, deflate, br
content-length: 1337
content-type: application/json
host: ${host}
user-agent: axios/1.2.3
x-amzn-trace-id: <trace id for support>
x-hmac-sha256: <hex>

{
  "id": "${uuid.v4()}",
  "type": "assessment",
  "action": "updated",
  "time": "${new Date().toISOString()}",
  "detail": {
    "assessmentId": ${uuid.v4()},
    "assessmentSequenceNumber": "223739000000000000562739939",
    "oldAssessmentSummary": { ... },
    "newAssessmentSummary": { ... },
  }
}
`}
          </code>
        </pre>
        <p>
          The webhook call can be authenticated by <a href="https://www.devglan.com/online-tools/hmac-sha256-online"> computing the HMAC-SHA256 hash of the request body</a> using the configured secret
          and comparing it to the <code>x-hmac-sha256</code> header.
        </p>
        <p>
          If delivery fails, Aveni will retry for up to 90 minutes at one minute intervals.
        </p>
        <p>
          Events will generally be delivered in order and only once, but this is not guaranteed:
        </p>
        <ul>
          <li>The <b>id</b> attribute can be used to de-duplicate events;</li>
          <li>The <b>time</b> attribute can be used to estimate latency from when the event was raised;</li>
          <li>If a sequence number is available in the <b>details</b>, it can be used to order events for a given entity.</li>
        </ul>
        <h3>Common Attributes</h3>
        <dl>
          <dt className="font-bold">id</dt>
          <dd className="pl-4">unique event id</dd>
          <dt className="font-bold">time</dt>
          <dd className="pl-4">approximate time the event was raised</dd>
          <dt className="font-bold">type</dt>
          <dd className="pl-4">event type, which determines the <b>action</b> and the <b>detail</b> format</dd>
          <dt className="font-bold">action</dt>
          <dd className="pl-4">verb summarizing the reason why the event was raised</dd>
          <dt className="font-bold">detail</dt>
          <dd className="pl-4">type-specific payload</dd>
        </dl>
        <h3>Assessment Modification events</h3>
        <p>
          The <i>assessment</i> event <b>type</b> is raised with a <i>created / updated / deleted</i> <b>action</b>.
        </p>
        <dl>
          <dt className="font-bold">assessmentId</dt>
          <dd className="pl-4">assessment uuid, consistent with the summaries below</dd>
          <dt className="font-bold">assessmentSequenceNumber</dt>
          <dd className="pl-4">sequence number (encoded as a string); monotonically increasing for a given assessment id</dd>
          <dt className="font-bold">oldAssessmentSummary</dt>
          <dd className="pl-4">assessment summary before event; undefined if it was just created</dd>
          <dt className="font-bold">newAssessmentSummary</dt>
          <dd className="pl-4">assessment summary after event; undefined if it was just deleted</dd>
        </dl>
        <p>
          The event only ever carries assessment summaries &mdash; which are Assessments
          without the <code>records</code> property, which carries the question-level evaluations.
        </p>
        <p>
          <span className="pr-3">
            <FontAwesomeIcon icon={faCloudDownload} className="text-neutral-600" />
          </span>
          <a href={`https://${config.dataIntegration.domain}/docs/v1/assessments-schema.json`} target="_blank" rel="noreferrer">Download Assessment JSON Schema</a>
        </p>
      </article>
    </section>
  );
};

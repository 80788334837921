import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons/faCircleExclamation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export const ErrorAlert: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <div className="bg-red-100 rounded-lg py-4 px-6 text-red-700 flex w-full" role="alert">
      <div className="mr-4 text-lg fill-current align-baseline" aria-hidden="true">
        <FontAwesomeIcon icon={faCircleExclamation} />
      </div>
      <div className="grow">{children}</div>
    </div>
  );
};

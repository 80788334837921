import { IngestConnectorDeploymentResult, IngestConnectorDeploymentStatus } from "@aveni-ingest/connectors-config";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons/faArrowCircleLeft";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons/faEllipsis";
import { faExclamation } from "@fortawesome/free-solid-svg-icons/faExclamation";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons/faExclamationCircle";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons/faExclamationTriangle";
import { faPause } from "@fortawesome/free-solid-svg-icons/faPause";
import { faPlay } from "@fortawesome/free-solid-svg-icons/faPlay";
import { faXmark } from "@fortawesome/free-solid-svg-icons/faXmark";

import { IngestIntegrationDeploymentStatus } from "../../../model";

export interface StatusPresentation {
  readonly icon: IconDefinition;
  readonly label: string;
  readonly disabled: boolean;
  readonly textColor: string;
  readonly borderColor: string;
  readonly alert?: AlertStatusPresentation;
}

export interface AlertStatusPresentation {
  readonly level: "info" | "warning" | "error";
  readonly text: string;
}

export interface DeploymentStatusPresentation {
  readonly icon: IconDefinition;
  readonly label: string;
}

export function integrationStatusPresentation(status: IngestIntegrationDeploymentStatus): StatusPresentation {
  switch (status.state) {
    case "queued":
      return queued;
    case "executing":
      return executing;
    case "timeout":
      return timeout;
    case "failed":
      return failed;

    case "completed":
      if (status.result) {
        return deploymentResultStatusPresentation(status.result);
      } else {
        // this should never happen - so hedge a bit
        return fallback;
      }

    default:
      throw new Error("Unexpected state: " + status.state);
  }
}

export function deploymentResultStatusPresentation(result: IngestConnectorDeploymentResult): StatusPresentation {
  switch (result.status) {
    case "ok":
      return completedOk;
    case "skipped":
      return completedSkipped;
    case "rollback":
      return completedRollback(result.message);
    case "warning":
      return completedWarning(result.message);
    case "error":
      return completedError(result.message);
    default:
      throw new Error("Unexpected result status: " + result.status);
  }
}

export function deploymentStatusPresentation(status: IngestConnectorDeploymentStatus): DeploymentStatusPresentation {
  return deploymentStatusLookup[status];
}

const queued: StatusPresentation = {
  label: "Queued",
  icon: faPause,
  disabled: true,
  textColor: "text-gray-500",
  borderColor: "border-gray-500",
} as const;

const executing: StatusPresentation = {
  label: "Executing",
  icon: faPlay,
  disabled: true,
  textColor: "text-gray-500",
  borderColor: "border-gray-500",
} as const;

const timeout: StatusPresentation = {
  label: "Timeout",
  icon: faXmark,
  disabled: true,
  textColor: "text-red-500",
  borderColor: "border-red-500",
  alert: {
    level: "error",
    text: "The last change timed out",
  } as const,
} as const;

const failed: StatusPresentation = {
  label: "Failed",
  icon: faXmark,
  disabled: true,
  textColor: "text-red-500",
  borderColor: "border-red-500",
  alert: {
    level: "error",
    text: "The last change failed",
  } as const,
} as const;

const fallback: StatusPresentation = {
  label: "Pending",
  icon: faEllipsis,
  disabled: true,
  textColor: "text-gray-500",
  borderColor: "border-gray-500",
} as const;

const completedOk: StatusPresentation = {
  label: "Completed OK",
  icon: faCheck,
  disabled: false,
  textColor: "text-green-500",
  borderColor: "border-green-500",
} as const;

const completedSkipped: StatusPresentation = {
  label: "Skipped",
  icon: faEllipsis,
  disabled: false,
  textColor: "text-sky-500",
  borderColor: "border-sky-500",
} as const;

const completedRollback: (message: string | undefined) => StatusPresentation = (message) =>
  ({
    label: "Rollback",
    icon: faArrowCircleLeft,
    disabled: false,
    textColor: "text-orange-500",
    borderColor: "border-orange-500",
    alert: {
      level: "error",
      text: message ?? "The last change was rolled back",
    },
  } as const);

const completedWarning: (message: string | undefined) => StatusPresentation = (message) =>
  ({
    label: "Warning",
    icon: faExclamation,
    disabled: false,
    textColor: "text-yellow-500",
    borderColor: "border-yellow-500",
    alert: {
      level: "warning",
      text: message ?? "The last change did not complete cleanly",
    },
  } as const);

const completedError: (message: string | undefined) => StatusPresentation = (message) =>
  ({
    label: "Error",
    icon: faXmark,
    disabled: false,
    textColor: "text-red-500",
    borderColor: "border-red-500",
    alert: {
      level: "error",
      text: message ?? "The last change did not complete cleanly",
    } as const,
  } as const);

const deploymentStatusLookup: Record<IngestConnectorDeploymentStatus, DeploymentStatusPresentation> = {
  ok: { icon: faCheck, label: "OK" } as const,
  skipped: { icon: faEllipsis, label: "Skipped" } as const,
  rollback: { icon: faArrowCircleLeft, label: "Rollback" } as const,
  warning: { icon: faExclamationTriangle, label: "Warning" } as const,
  error: { icon: faExclamationCircle, label: "Error" } as const,
} as const;

import { DataIntgWebhookConfig } from "@aveni-ingest/connectors-config/connectors/dataintg-webhook";
import objectHash from "object-hash";
import { useState } from "react";
import { PulseLoader } from "react-spinners";
import * as uuid from "uuid";
import isURL from "validator/es/lib/isURL";
import { IngestIntegration } from "../../../../model";
import { PrimaryButton } from "../../components/buttons";
import { Input, Label } from "../../components/forms";
import { ActivationPrompt } from "../../components/integration/ActivationPrompt";
import { useOptimisticUpdateIntegration } from "../../hooks";
import { IntegrationViewProps } from "../types";
import { DataIntgWebhookInfoCard } from "./DataIntgWebhookInfoCard";

export type DataIntgWebhookViewProps = IntegrationViewProps;

export const DataIntgWebhookView: React.FC<DataIntgWebhookViewProps> = (props) => {
  const { integration } = props;
  const configuration = integration.configuration as Partial<DataIntgWebhookConfig>;

  return (
    <>
      {isConfigurationValid(configuration.url, configuration.secret) && (
        <ActivationPrompt
          integration={integration}
          inactive={<span>Activate to enable the webhook.</span>}
          active={
            <span>
              The webhook will now receive events as configured below.
              <br />
              Review the information below for details about the webhook event format.
            </span>
          }
        />
      )}
      <WebhookConfigCard integration={integration} />
      <DataIntgWebhookInfoCard url={configuration.url} />
    </>
  );
};

const isConfigurationValid = (url: string | undefined, secret: string | undefined) => {
  return url && isURL(url, { protocols: ["http", "https"], require_protocol: true }) && secret;
};

const WebhookConfigCard: React.FC<{ integration: IngestIntegration }> = (props) => {
  const { integration } = props;
  const initialConfiguration = integration.configuration as DataIntgWebhookConfig;

  const [url, setUrl] = useState(initialConfiguration.url ?? "");
  const [secret, setSecret] = useState(initialConfiguration.secret ?? objectHash(uuid.v4()));
  const [assessmentEventType, setAssessmentEventType] = useState(
    initialConfiguration.eventTypes ? initialConfiguration.eventTypes.includes("assessment") : true,
  );

  const { mutate: updateIntegrationConfiguration, isLoading: isUpdatePending } = useOptimisticUpdateIntegration(
    integration.integrationId,
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    saveConfiguration();
  };

  const saveConfiguration = () => {
    const eventTypes = [];
    if (assessmentEventType) eventTypes.push("assessment");

    updateIntegrationConfiguration({
      configuration: {
        url,
        secret,
        eventTypes,
      },
    });
  };

  return (
    <section className="block p-6 mb-4 rounded-lg shadow-lg bg-white">
      <h2 className="text-gray-900 text-lg leading-tight font-medium mb-4">
        Configuration
        {isUpdatePending && (
          <span className="ml-2">
            <PulseLoader color="#2563EB" size="10px" />
          </span>
        )}
      </h2>

      <form className="flex flex-col space-y-4" onSubmit={handleSubmit}>
        <div>
          <Label htmlFor="url">Webhook URL</Label>
          <Input placeholder="https://example.com/webhook" value={url} onChange={(e) => setUrl(e.target.value)} />
        </div>
        <div>
          <Label htmlFor="secret">Verification Secret</Label>
          <Input required={true} value={secret} onChange={(e) => setSecret(e.target.value)} maxLength={256} />
        </div>
        <div>
          <p className="text-gray-700 text-base mb-2">Events will be sent for:</p>
          <li className="list-none text-gray-700">
            <input
              name="assessment"
              type="checkbox"
              checked={assessmentEventType}
              onChange={(e) => setAssessmentEventType(e.target.checked)}
            />
            <label htmlFor="assessment" className="pl-2">
              Assessments
            </label>
          </li>
        </div>
        <div className="mt-4">
          <PrimaryButton disabled={!isConfigurationValid(url, secret)}>Save</PrimaryButton>
        </div>
      </form>
    </section>
  );
};

import { useAuth0 } from "@auth0/auth0-react";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons/faCircleExclamation";
import { faSignOut } from "@fortawesome/free-solid-svg-icons/faSignOut";
import { faWarning } from "@fortawesome/free-solid-svg-icons/faWarning";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TimeAgo from "javascript-time-ago";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import { IngestIntegration, IngestIntegrationDeploymentStatus, IngestIntegrationState } from "../../../model";
import aveniLogo from "../assets/images/aveni.png";
import { ErrorAlert } from "../components/alerts/ErrorAlert";
import { LightButton } from "../components/buttons";
import { ClickToEdit } from "../components/ClickToEdit";
import { IntegrationCatalogNavButton } from "../components/IntegrationCatalogNavButton";
import { useDeploymentStatus, useIntegrations, useUpdateIntegration } from "../hooks";

const IntegrationsPage = () => {
  const { logout } = useAuth0();
  const { data: integrations, error, isLoading, refetch } = useIntegrations();

  const onLogout = () => {
    logout({
      returnTo: window.location.origin,
    });
  };

  useEffect(() => {
    window.document.title = "Aveni Integrations";
  }, []);

  return (
    <>
      <div className="max-w-screen-lg m-auto px-4 py-8 flex flex-col space-y-4">
        <div className="flex items-center space-x-4">
          <h1 className="grow text-3xl text-aveni-blue font-light leading-[1.5]">
            Integrations
            {isLoading && (
              <span className="ml-4">
                <PulseLoader color="gray" size="10px" />
              </span>
            )}
          </h1>
          <IntegrationCatalogNavButton />

          <LightButton onClick={onLogout}>
            <span className="hidden md:inline mr-2">Sign out</span>
            <FontAwesomeIcon icon={faSignOut} />
          </LightButton>

          {/* <HomeIcon /> */}
        </div>

        {!!error && <IntegrationsErrorAlert error={error} />}

        {!!integrations && <IntegrationsCount count={integrations.length} />}

        {!!integrations && integrations.length === 0 && <FirstUseInfoCard />}

        {!!integrations &&
          integrations.map((item) => <IntegrationCard key={item.integrationId} integration={item} refresh={refetch} />)}
      </div>
    </>
  );
};

const FirstUseInfoCard = () => {
  return (
    <div className="pt-8 flex">
      <div className="m-auto p-4 md:p-6 rounded-lg shadow-lg bg-white flex flex-col space-y-4">
        <div className="flex space-x-4 text-3xl h-[32px] items-center m-2 self-center">
          <img className="h-[40px]" src={aveniLogo} alt="Aveni" />
        </div>
        <h2 className="text-xl text-neutral-800 font-light text-center ">Ready for integration</h2>
        <div className="flex flex-col space-y-2 font-light text-center">
          <p>Aveni Integrations are managed here.</p>
          <p>Browse the Integration Catalogue to begin installation.</p>
        </div>
        <div className="block self-center">
          <IntegrationCatalogNavButton />
        </div>
      </div>
    </div>
  );
};

const IntegrationsCount = (props: { count: number }) => {
  const { count } = props;
  return (
    <p className="text-neutral-600 text-lg">
      <span className="font-medium">
        {count === 0 ? "No integrations" : count === 1 ? "1 integration" : `${count} integrations`}
        <span className="font-light"> installed</span>
      </span>
    </p>
  );
};

const IntegrationCard = (props: { integration: IngestIntegration; refresh: () => void }) => {
  const { integration, refresh } = props;

  const navigate = useNavigate();

  // local copy of integration so we can update it as the deployment status changes
  const [lastDeploymentState, setLastDeploymentState] = useState<string | undefined>();
  const { mutate: updateIntegration } = useUpdateIntegration(integration.integrationId);

  // decided not to show a loading indicator for this as the default behaviour is not to render anything if OK
  const { data: deploymentStatus } = useDeploymentStatus(integration.integrationId, integration.trackingToken);

  useEffect(() => {
    const deploymentState = deploymentStatus?.state;
    if (deploymentState !== lastDeploymentState) {
      setLastDeploymentState(deploymentState);
      refresh();
    }
  }, [deploymentStatus, lastDeploymentState, refresh]);

  const opts = stateOptionsLookup[integration.state];

  const onMoreDetail = () => {
    navigate(`/integrations/${integration.integrationId}`);
  };

  return (
    <div className="block p-6 mb-6 rounded-lg shadow-lg bg-white">
      <div className="flex space-x-2">
        <h2 className="text-gray-900 text-lg leading-tight font-medium mb-2 grow">
          {!integration.deleted ? (
            <ClickToEdit
              text={integration.name}
              onChange={(name) => {
                updateIntegration({ name });
              }}
            />
          ) : (
            integration.name
          )}
        </h2>
        {!!opts.pulse && (
          <span>
            <PulseLoader size="10px" color={opts.pulse} />
          </span>
        )}
      </div>
      {deploymentStatus && <DeploymentStatusAlert status={deploymentStatus} />}
      {integration.deleted && <div className="font-light">(recently uninstalled)</div>}
      <div className="flex space-x-2 mt-4">
        <div className="flex space-x-2">
          <LightButton onClick={onMoreDetail} disabled={integration.deleted}>
            More detail
          </LightButton>
        </div>
        <div className="flex grow space-x-2 justify-end">
          <div
            className={`inline-block px-6 py-2 border-2 ${opts.borderColor} ${opts.textColor} font-medium text-xs leading-tight uppercase rounded-full`}
          >
            {opts.text}
          </div>
        </div>
      </div>
    </div>
  );
};

const DeploymentStatusAlert = (props: { status: IngestIntegrationDeploymentStatus }) => {
  const { status } = props;

  switch (status.state) {
    case "timeout":
      return <EmbeddedErrorAlert text="The last change timed out" timestamp={status.lastUpdatedTimestamp} />;
    case "failed":
      return <EmbeddedErrorAlert text="The last change failed to complete" timestamp={status.lastUpdatedTimestamp} />;
  }

  const result = status.result;
  if (result) {
    const message = result.message;
    switch (result.status) {
      case "rollback":
        return (
          <EmbeddedWarningAlert
            text={message ?? "The last change was rolled back"}
            timestamp={status.lastUpdatedTimestamp}
          />
        );
      case "warning":
        return (
          <EmbeddedWarningAlert
            text={message ?? "The last change did not complete cleanly"}
            timestamp={status.lastUpdatedTimestamp}
          />
        );
      case "error":
        return (
          <EmbeddedErrorAlert
            text={message ?? "The last change did not complete cleanly"}
            timestamp={status.lastUpdatedTimestamp}
          />
        );
    }
  }

  return <></>;
};

const EmbeddedWarningAlert = (props: { text: string; timestamp?: string }) => {
  const { text, timestamp } = props;
  const timeAgo = new TimeAgo("en-GB");

  return (
    <p className="text-yellow-700 bg-yellow-100 px-4 py-2 rounded-lg flex w-full">
      <div className="mr-4 fill-current align-baseline " aria-hidden="true">
        <FontAwesomeIcon icon={faWarning} />
      </div>
      <div className="grow">
        <p>{text}</p>
        {!!timestamp && <p className="font-light text-xs">{timeAgo.format(new Date(timestamp).getTime())}</p>}
      </div>
    </p>
  );
};

const EmbeddedErrorAlert = (props: { text: string; timestamp?: string }) => {
  const { text, timestamp } = props;
  const timeAgo = new TimeAgo("en-GB");

  return (
    <p className="text-red-700 bg-red-100 px-4 py-2 rounded-lg flex w-full">
      <div className="mr-4 fill-current align-baseline " aria-hidden="true">
        <FontAwesomeIcon icon={faCircleExclamation} />
      </div>
      <div className="grow">
        <p>{text}</p>
        {!!timestamp && <p className="font-light text-xs">{timeAgo.format(new Date(timestamp).getTime())}</p>}
      </div>
    </p>
  );
};

const IntegrationsErrorAlert = (props: { error: unknown }) => {
  return (
    <ErrorAlert>
      <p className="font-medium text-lg mb-2">Service Problem</p>
      <p>Sorry, we can't seem to load the Integrations list right now.</p>
      <p>If this problem persists, please contact Aveni support.</p>
    </ErrorAlert>
  );
};

interface StateOptions {
  text: string;
  textColor: string;
  borderColor: string;
  pulse?: string;
}

const stateOptionsLookup: Record<IngestIntegrationState, StateOptions> = {
  "active": {
    text: "Active",
    textColor: "text-blue-600",
    borderColor: "border-blue-600",
  },
  "active-pending": {
    text: "Activating",
    textColor: "text-blue-600",
    borderColor: "border-blue-600",
    pulse: "#2563EB",
  },
  "inactive": {
    text: "Inactive",
    textColor: "text-gray-500",
    borderColor: "border-gray-400",
  },
  "inactive-pending": {
    text: "Deactivating",
    textColor: "text-red-600",
    borderColor: "border-red-600",
    pulse: "red",
  },
};

export default IntegrationsPage;

import { WarningAlert } from "./WarningAlert";

export const AuthorizationExpiringAlert: React.FC = () => {
  return (
    <WarningAlert>
      <p className="font-medium text-lg mb-2">Authorisation needed soon</p>
      <p>You should reauthorize now to maintain your connection</p>
    </WarningAlert>
  );
};

import { useAuth0 } from "@auth0/auth0-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { CreateIngestIntegrationOptions, IngestIntegration, UpdateIngestIntegrationOptions } from "../../model";
import * as api from "./api";
import { descriptionUrl } from "./assets/catalog";

export const useIntegrations = () => {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: ["integrations"],
    queryFn: async () => {
      api.setAccessToken(await getAccessTokenSilently());
      return await api.getIngestIntegrations();
    },
    onSuccess(integrations) {
      integrations.forEach((integration) =>
        queryClient.setQueryData(["integrations", integration.integrationId], integration),
      );
    },
  });
};

export const useIntegration = (integrationId: string) => {
  const { getAccessTokenSilently } = useAuth0();
  return useQuery({
    queryKey: ["integrations", integrationId],
    queryFn: async () => {
      api.setAccessToken(await getAccessTokenSilently());
      return await api.getIngestIntegration(integrationId);
    },
  });
};

export const useCreateIntegration = () => {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();
  return useMutation(
    async (options: CreateIngestIntegrationOptions) => {
      api.setAccessToken(await getAccessTokenSilently());
      return await api.createIngestIntegration(options);
    },
    {
      onSuccess: (result) => {
        queryClient.setQueryData(["integrations", result.integrationId], result);
      },
    },
  );
};

export const useUpdateIntegration = (integrationId: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    async (options: UpdateIngestIntegrationOptions) => {
      api.setAccessToken(await getAccessTokenSilently());
      return await api.updateIngestIntegration(integrationId, options);
    },
    {
      onSuccess: (result) => {
        queryClient.setQueryData(["integrations", integrationId!], result);
        queryClient.invalidateQueries(["integrations", integrationId]);
      },
    },
  );
};

export const useOptimisticUpdateIntegration = (integrationId: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    async (options: UpdateIngestIntegrationOptions) => {
      api.setAccessToken(await getAccessTokenSilently());
      return await api.updateIngestIntegration(integrationId, options);
    },
    {
      onMutate: async (options: UpdateIngestIntegrationOptions) => {
        await queryClient.cancelQueries(["integrations", integrationId]);

        // snapshot
        const previousValue = queryClient.getQueryData<IngestIntegration>(["integrations", integrationId]);

        if (previousValue) {
          const name = options.name ?? previousValue.name;
          const configuration = options.configuration ?? previousValue.configuration;
          const state = options.state ? options.state + "-pending" : previousValue.state;
          queryClient.setQueryData(["integrations", integrationId], {
            ...previousValue,
            name,
            configuration,
            state,
            dirty: false,
          });
        }

        // context for rollback
        return { previousValue };
      },
      onError: (error, variables, context) => {
        if (context) {
          queryClient.setQueryData(["integrations", integrationId], context.previousValue);
        }
      },
      onSettled: () => {
        // re-read in any case
        queryClient.invalidateQueries(["integrations", integrationId]);
      },
    },
  );
};

export const useDeleteIntegration = (integrationId: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    async () => {
      api.setAccessToken(await getAccessTokenSilently());
      return await api.deleteIngestIntegration(integrationId, {});
    },
    {
      onSuccess: (result) => {
        if (result) {
          queryClient.setQueryData(["integrations", integrationId], result);
        } else {
          queryClient.removeQueries(["integrations", integrationId]);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(["integrations", integrationId]);
      },
    },
  );
};

export const useConnectors = () => {
  const { getAccessTokenSilently } = useAuth0();
  return useQuery({
    queryKey: ["connectors"],
    queryFn: async () => {
      api.setAccessToken(await getAccessTokenSilently());
      return await api.getIngestConnectors();
    },
    staleTime: 120000,
  });
};

export const useDeploymentStatus = (integrationId: string, trackingToken: string | undefined) => {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: ["deployments", trackingToken!],
    queryFn: async () => {
      api.setAccessToken(await getAccessTokenSilently());
      return await api.getDeploymentStatus(trackingToken!);
    },
    enabled: !!trackingToken,
    refetchInterval: (data) => (data?.state === "queued" || data?.state === "executing" ? 500 : false),
    onSuccess: (data) => {
      if (data?.state !== "queued" && data?.state !== "executing") {
        queryClient.invalidateQueries(["integrations", integrationId]);
      }
    },
  });
};

export const useConnectorDescription = (connectorId: string, options?: { enabled?: boolean }) => {
  return useQuery({
    queryKey: ["connector-description", connectorId],
    queryFn: async () => {
      const url = descriptionUrl(connectorId);
      if (url) {
        return await fetch(url).then((response) => {
          return response.text();
        });
      }
    },
    staleTime: 120000,
    enabled: options?.enabled,
  });
};

export const useMarkdown = (markdownUrl: string, options?: { enabled?: boolean }) => {
  return useQuery({
    queryKey: ["markdown", markdownUrl],
    queryFn: async () => {
      return await fetch(markdownUrl).then((response) => {
        return response.text();
      });
    },
    staleTime: 600000,
    enabled: options?.enabled,
  });
};

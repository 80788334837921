import { useState } from "react";
import { PulseLoader } from "react-spinners";
import { OnChangeStatus } from "vanilla-jsoneditor";
import { IngestConnectorInfo, IngestIntegration } from "../../../../model";
import { useOptimisticUpdateIntegration } from "../../hooks";
import { PrimaryButton } from "../buttons";
import SvelteJSONEditor from "../SvelteJSONEditor";

export type JsonConfigurationCardProps = {
  integration: IngestIntegration;
  connector: IngestConnectorInfo; // not used yet - but will be needed at some point for schema validation
};

const JsonConfigurationCard: React.FC<JsonConfigurationCardProps> = (props) => {
  const { integration } = props;

  const [configuration, setConfiguration] = useState<{ json?: object; text?: string }>({
    json: integration.configuration,
  });

  const { mutate: updateIntegrationConfiguration, isLoading: isUpdatePending } = useOptimisticUpdateIntegration(
    integration.integrationId,
  );

  const [isValid, setIsValid] = useState(true);

  const saveConfiguration = () => {
    try {
      const parsed = JSON.parse(configuration.text!);
      if (parsed) {
        updateIntegrationConfiguration({
          configuration: parsed,
        });
      }
    } catch (e) {
      // do nothing
    }
  };

  // TODO would be nice to add schema docs and validation here
  // (delayed until we actually need it since this is just a fallback UI)

  return (
    <section className="block p-6 mb-4 rounded-lg shadow-lg bg-white">
      <h2 className="text-gray-900 text-lg leading-tight font-medium mb-4">
        Configuration
        {isUpdatePending && (
          <span className="ml-2">
            <PulseLoader color="#2563EB" size="10px" />
          </span>
        )}
      </h2>

      <div className="mb-4">
        <SvelteJSONEditor
          mode="text"
          content={configuration}
          tabSize={2}
          mainMenuBar={false}
          navigationBar={false}
          onChange={(content: any, _previousContent: any, changeStatus: OnChangeStatus) => {
            setIsValid(!("parseError" in changeStatus.contentErrors));
            setConfiguration(content);
          }}
        />
      </div>

      <div className="mt-4">
        <PrimaryButton onClick={saveConfiguration} disabled={!isValid}>
          Save
        </PrimaryButton>
      </div>
    </section>
  );
};

export default JsonConfigurationCard;

import { useAuth0 } from "@auth0/auth0-react";
import { faSignIn } from "@fortawesome/free-solid-svg-icons/faSignIn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import aveniLogo from "../assets/images/aveni.png";
import { claimCheck } from "../auth";
import { PrimaryButton } from "../components/buttons";
import UnauthorizedPage from "./UnauthorizedPage";

const LandingPage: React.FC = () => {
  const { loginWithRedirect, isAuthenticated, user } = useAuth0();

  const onSignIn = () => {
    loginWithRedirect();
  };

  useEffect(() => {
    window.document.title = "Aveni Integration Management";
  }, []);

  if (isAuthenticated) {
    // extra claim check is not essential as it'll be covered by ProtectedRoute
    // but it seems neater to do it here too so they don't progress past the landing page
    const authorized = claimCheck(user);
    if (authorized) {
      return <Navigate to={"/integrations"} replace={true} />;
    } else {
      return <UnauthorizedPage />;
    }
  }

  return (
    <div className="w-full h-screen overflow-hidden">
      <div className="bg-cover bg-bridge w-full h-full min-w-[1280px]">
        <div className="relative bg-cover w-full h-full bg-gradient-to-tl from-transparent to-neutral-200 to-opacity-10">
          <div className="flex justify-center items-center pl-12 pt-12 min-w-96 min-h-[25rem] w-1/3 h-1/2 text-aveni-blue">
            <div className="flex flex-col space-y-6 w-96 ">
              <h1 className="text-5xl text-aveni-blue drop-shadow ">
                <img src={aveniLogo} alt="Aveni" />
                Integration Management
              </h1>
              <p className="text-2xl text-neutral-800 font-light leading-8">Connect Aveni to your corporate systems</p>
              <div>
                <PrimaryButton onClick={onSignIn}>
                  Sign in
                  <FontAwesomeIcon icon={faSignIn} className="ml-2" />
                </PrimaryButton>
              </div>
            </div>
          </div>
          <div className="absolute bottom-1 right-1">
            <PhotoCredit />
          </div>
        </div>
      </div>
    </div>
  );
};

const PhotoCredit = () => {
  return (
    <div className="font-light text-sm text-aveni-blue p-1 rounded opacity-50 hover:opacity-100 hover:bg-white">
      <p>Queensferry Crossing, Edinburgh</p>
      <p>
        <span>Photo by </span>
        <a className="hover:underline" target="_blank" href="https://unsplash.com/@john_cameron" rel="noreferrer">
          John Cameron
        </a>
        <span> on </span>
        <a className="hover:underline" target="_blank" href="https://unsplash.com/photos/OVAqQe8o4BI" rel="noreferrer">
          Unsplash
        </a>
      </p>
    </div>
  );
};

export default LandingPage;

import { ErrorAlert } from "./ErrorAlert";

export const IntegrationErrorAlert = (props: { error: unknown }) => {
  return (
    <ErrorAlert>
      <p className="font-medium text-lg mb-2">Service Problem</p>
      <p>Sorry, we can't seem to load details about the Integration right now.</p>
    </ErrorAlert>
  );
};

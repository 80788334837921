import React from "react";

export const Input: React.FC<React.InputHTMLAttributes<HTMLInputElement>> = React.forwardRef<
  HTMLInputElement,
  React.ComponentPropsWithoutRef<"input">
>(({ children, ...props }, ref) => {
  switch (props.type) {
    case "text":
    case undefined:
      return (
        <TextInput ref={ref} {...props}>
          {children}
        </TextInput>
      );
    case "submit":
      return (
        <SubmitInput ref={ref} {...props}>
          {children}
        </SubmitInput>
      );
    default:
      return (
        <input ref={ref} {...props}>
          {children}
        </input>
      );
  }
});

// https://tailwind-elements.com/docs/standard/forms/inputs/#text
const TextInput = React.forwardRef<HTMLInputElement, React.ComponentPropsWithoutRef<"input">>(
  ({ children, ...props }, ref) => (
    <input
      ref={ref}
      {...props}
      className={`
    form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    ${props.disabled || props.readOnly ? "bg-gray-100" : "bg-white"} bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
    ${props.className || ""}}
    `}
    >
      {children}
    </input>
  ),
);

// same as PrimaryButton
const SubmitInput = React.forwardRef<HTMLInputElement, React.ComponentPropsWithoutRef<"input">>(
  ({ children, ...props }, ref) => (
    <input
      ref={ref}
      {...props}
      className={
        props.disabled
          ? `inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md focus:outline-none focus:ring-0 transition duration-150 ease-in-out pointer-events-none opacity-60 ${
              props.className || ""
            }`
          : `inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ${
              props.className || ""
            }`
      }
    >
      {children}
    </input>
  ),
);

import { IngestConnectorDeploymentResult } from "@aveni-ingest/connectors-config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { deploymentResultStatusPresentation, deploymentStatusPresentation } from "../../utils/status-presentation";

export type DeploymentResultModalDialogProps = {
  id: string;
  result: IngestConnectorDeploymentResult;
};

export const DeploymentResultModalDialog: React.FC<DeploymentResultModalDialogProps> = (props) => {
  const { result } = props;

  const opts = deploymentResultStatusPresentation(result);

  const startedTimestamp = new Date(result.startedTimestamp);
  const completedTimestamp = new Date(result.completedTimestamp);
  const durationSeconds = (completedTimestamp.getTime() - startedTimestamp.getTime()) / 1000;

  const infoEntries = result.info ? Object.entries(result.info) : [];

  return (
    <div
      className="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
      id={props.id}
      tabIndex={-1}
      aria-labelledby={opts.label}
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable relative w-auto pointer-events-none">
        <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
          <div className="modal-header flex flex-shrink-0 items-center justify-between py-2 px-4 border-b border-gray-200 rounded-t-md">
            <div className="text-lg font-medium leading-normal text-gray-900">{opts.label}</div>
            <button
              type="button"
              className="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body relative px-4 py-2">
            <div className="flex flex-col space-y-4">
              {!!result.message && result.message !== opts.label && <p>{result.message}</p>}
              <div className="flex space-x-2 text-sm">
                <div className="font-medium">
                  <p>Started:</p>
                  <p>Completed:</p>
                  <p>Duration:</p>
                </div>
                <div className="grow">
                  <p>{startedTimestamp.toLocaleString()}</p>
                  <p>{completedTimestamp.toLocaleString()}</p>
                  <p>{durationSeconds.toFixed(1)} seconds</p>
                </div>
              </div>

              {result.steps.length > 0 && (
                <section>
                  <p className="font-medium">Deployment Log</p>
                  <table className="text-sm border-separate table-y-spacing-2">
                    <tbody>
                      {result.steps.map((step, i) => (
                        <tr key={i}>
                          <td className="font-medium text-center pr-2 align-baseline">
                            <FontAwesomeIcon
                              icon={deploymentStatusPresentation(step.status).icon}
                              title={deploymentStatusPresentation(step.status).label}
                            />
                          </td>
                          <td>
                            <p>{step.label}</p>
                            {!!step.message && <p className="font-light">{step.message}</p>}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </section>
              )}

              {infoEntries.length > 0 && (
                <section>
                  <p className="font-medium mb-2">Info</p>
                  <table className="text-xs">
                    <tbody>
                      {infoEntries.map((kv) => (
                        <tr key={kv[0]}>
                          <th className="font-medium text-left pr-2">{kv[0]}:</th>
                          <td>
                            <pre>{String(kv[1])}</pre>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </section>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import { DataIntgApiKeyInfo } from "@aveni-ingest/connectors-config/connectors/dataintg-apikey";
import { Input, Label } from "../../components/forms";
import { ActivationPrompt } from "../../components/integration/ActivationPrompt";
import { useDeploymentStatus } from "../../hooks";
import { IntegrationViewProps } from "../types";
import { DataIntgApiKeyHelpCard } from "./DataIntgApiKeyHelpCard";

export type DataIntgApiKeyViewProps = IntegrationViewProps;

export const DataIntgApiKeyView: React.FC<DataIntgApiKeyViewProps> = (props) => {
  const { integration } = props;
  const { integrationId, trackingToken } = integration;

  const { data: deploymentStatus } = useDeploymentStatus(integrationId, trackingToken);
  const info = deploymentStatus?.result?.info as DataIntgApiKeyInfo | undefined;

  return (
    <>
      <ActivationPrompt
        integration={integration}
        inactive={
          <span>
            Activate to create an API Key for use with the{" "}
            <a href="/docs/data-integration/api" className="font-medium underline" target="_blank">
              Aveni Data Integration API
            </a>
            .
          </span>
        }
        active={info ? <ApiKeyView apiKey={info.apiKey} /> : null}
        activePending={<span>Creating API Key...</span>}
        inactivePending={<span>Deleting API Key...</span>}
      />
      {integration.state === "active" && info?.apiKey && <DataIntgApiKeyHelpCard apiKey={info.apiKey} />}
    </>
  );
};

const ApiKeyView: React.FC<{ apiKey: string }> = (props) => {
  return (
    <div className="flex justify-left">
      <div className="w-full mb-2">
        <Label htmlFor="dataintg-apikey">API Key:</Label>
        <Input type="text" id="dataintg-apikey" value={props.apiKey} readOnly={true} />
      </div>
    </div>
  );
};

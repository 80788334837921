const config = {
  region: process.env.REACT_APP_REGION,
  api: {
    url: process.env.REACT_APP_API_URL,
  },
  dataIntegration: {
    domain: process.env.REACT_APP_DATA_INTEGRATION_DOMAIN,
  },
  auth: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientId: process.env.REACT_APP_OAUTH_CLIENT_ID,
    audience: process.env.REACT_APP_OAUTH_AUDIENCE,
    scope: process.env.REACT_APP_OAUTH_SCOPE,
  },
};

export default config;

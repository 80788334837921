import { useAuth0 } from "@auth0/auth0-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import { ProtectedRoute } from "./components/ProtectedRoute";
import CatalogPage from "./pages/CatalogPage";
import DataIntgApiDocs from "./pages/data-integration/DataIntgApiDocs";
import GenesysAppFoundryHelpPage from "./pages/genesys/GenesysAppFoundryHelpPage";
import GenesysSetupPage from "./pages/genesys/GenesysSetupPage";
import GenesysStartPage from "./pages/genesys/GenesysStartPage";
import IntegrationPage from "./pages/IntegrationPage";
import IntegrationsPage from "./pages/IntegrationsPage";
import LandingPage from "./pages/LandingPage";

TimeAgo.addDefaultLocale(en);

const queryClient = new QueryClient();

const App = () => {
  const { isLoading } = useAuth0();
  if (isLoading) {
    return (
      <div className="flex h-screen justify-center items-center p-8">
        <PulseLoader color="LightGray" size="10px" />
      </div>
    );
  }

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/integrations" element={<ProtectedRoute page={IntegrationsPage} />} />
          <Route path="/integrations/:integrationId" element={<ProtectedRoute page={IntegrationPage} />} />
          <Route path="/catalog" element={<ProtectedRoute page={CatalogPage} />} />
          <Route path="/catalog/genesys/start" element={<GenesysStartPage />} />
          <Route path="/catalog/genesys/setup" element={<ProtectedRoute page={GenesysSetupPage} />} />
          <Route path="/catalog/genesys/help" element={<GenesysAppFoundryHelpPage />} />
          <Route path="/docs/data-integration" element={<Navigate to="/docs/data-integration/api" replace={true} />} />
          <Route path="/docs/data-integration/api" element={<DataIntgApiDocs />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default App;

import { useEffect, useRef } from "react";
import { JSONEditor } from "vanilla-jsoneditor";
import "./SvelteJSONEditor.css";

// Directly from https://codesandbox.io/s/svelte-jsoneditor-react-59wxz?file=/src/SvelteJSONEditor.js
// Sample provided by the author of svelte-jsoneditor
export default function SvelteJSONEditor(props) {
  const refContainer = useRef(null);
  const refEditor = useRef(null);

  useEffect(() => {
    refEditor.current = new JSONEditor({
      target: refContainer.current,
      props: {},
    });

    return () => {
      if (refEditor.current) {
        refEditor.current.destroy();
        refEditor.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (refEditor.current) {
      refEditor.current.updateProps(props);
    }
  }, [props]);

  return <div className="svelte-jsoneditor-react" ref={refContainer}></div>;
}

import { GenesysConfig } from "@aveni-ingest/connectors-config/connectors/genesys";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { PulseLoader } from "react-spinners";
import { IngestConnectorInfo, IngestIntegration } from "../../../../model";
import { useIntegrationAuthorization } from "../../auth";
import { AuthorizationErrorAlert } from "../../components/alerts/AuthorizationErrorAlert";
import { PrimaryButton } from "../../components/buttons";
import { useUpdateIntegration } from "../../hooks";

export type GenesysAuthorizeCardProps = {
  integration: IngestIntegration;
  connector: IngestConnectorInfo;
};

export const GenesysAuthorizeCard: React.FC<GenesysAuthorizeCardProps> = (props) => {
  const { integration, connector } = props;
  const { authorize, isAuthorizing, authorizationError } = useIntegrationAuthorization();

  const configuration = (integration.configuration ?? {}) as Partial<GenesysConfig>;
  const environment = configuration.environment;
  const appFoundry = configuration.appFoundry;

  // connector should define a list of authorization domains
  let domains = connector.authorizationDomains;
  if (!domains || domains.length < 1) {
    domains = ["euw2.pure.cloud"];
  }

  const { mutateAsync: updateIntegration } = useUpdateIntegration(integration.integrationId);

  const [domain, setDomain] = useState(domains[0]);

  const handleAuthorizeClick = () => {
    updateIntegration({
      authorizationConfiguration: {
        type: "domain",
        domain,
      },
      configuration: {
        ...configuration,
        environment: domain,
      },
    }).then(authorize);
  };

  if (authorizationError) {
    return <AuthorizationErrorAlert error={authorizationError} />;
  } else if (isAuthorizing) {
    return (
      <section className="block p-6 mb-6 rounded-lg shadow-lg bg-white">
        <h2 className="text-gray-900 text-lg leading-tight font-medium mb-2">
          Authorising
          <span className="ml-2">
            <PulseLoader color="#2563EB" size="10px" />
          </span>
        </h2>
        <p className="text-gray-700 text-base mb-4">Authorise access to your Genesys Cloud CX organisation</p>
        <PrimaryButton disabled={true}>Authorise</PrimaryButton>
      </section>
    );
  } else {
    return (
      <section className="block p-6 rounded-lg shadow-lg bg-white ">
        <h2 className="text-gray-900 text-lg leading-tight font-medium mb-2">Authorisation Required</h2>
        <p className="text-gray-700 text-base mb-4">Authorise access to your Genesys Cloud CX organisation</p>

        {(!environment || !appFoundry) && (
          <div className="flex flex-col space-y-2 mb-4">
            <label htmlFor="domain" className="text-base font-medium text-gray-700">
              Genesys Environment (region):
            </label>
            <select
              className="w-fit
                form-control
                block
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white
                bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
              focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="domain"
              name="domain"
              value={domain}
              onChange={(e) => setDomain(e.currentTarget.value)}
            >
              {domains.map((d) => (
                <option key={d} value={d}>
                  {d}
                </option>
              ))}
            </select>
            <div className="text-gray-700 font-light">
              <FontAwesomeIcon className="mr-2" icon={faInfoCircle} />
              Refer to the URL shown in your browser for Genesys Cloud CX.
            </div>
          </div>
        )}

        <PrimaryButton onClick={handleAuthorizeClick}>Authorise</PrimaryButton>
      </section>
    );
  }
};

import { GenesysConfig } from "@aveni-ingest/connectors-config/connectors/genesys";
import _ from "lodash";
import { PulseLoader } from "react-spinners";
import { IngestIntegration } from "../../../../model";
import { useOptimisticUpdateIntegration } from "../../hooks";
import { GenesysOrganizationInfo } from "./api";

export type GenesysConfigCardProps = {
  integration: IngestIntegration;
  orgInfo: GenesysOrganizationInfo;
};

export const GenesysConfigCard: React.FC<GenesysConfigCardProps> = (props) => {
  const { integration, orgInfo } = props;
  const configuration = integration.configuration as Partial<GenesysConfig>;
  const queueIds = configuration.queueIds;

  const { mutate: updateIntegrationConfiguration, isLoading: isUpdatePending } = useOptimisticUpdateIntegration(
    integration.integrationId,
  );

  const setQueueIds = (nextQueueIds: string[]) => {
    updateIntegrationConfiguration({
      configuration: {
        ...integration.configuration,
        queueIds: nextQueueIds,
      },
    });
  };

  const divisionQueues = _.chain(orgInfo.queues)
    .groupBy("division.id")
    .map((queues, divisionId) => ({
      divisionId,
      divisionName: queues[0].division.name ?? `(${divisionId})`,
      queues: _.chain(queues)
        .map((queue) => ({
          queueId: queue.id,
          queueName: queue.name,
          selected: !queueIds || queueIds.includes(queue.id),
        }))
        .sortBy("queueName")
        .value(),
    }))
    .sortBy("divisionName")
    .filter((division) => division.queues.length > 0)
    .value();

  return (
    <section className="block p-6 mb-4 rounded-lg shadow-lg bg-white">
      <h2 className="text-gray-900 text-lg leading-tight font-medium mb-2">
        Configuration
        {isUpdatePending && (
          <span className="ml-2">
            <PulseLoader color="#2563EB" size="10px" />
          </span>
        )}
      </h2>

      {!queueIds ? (
        <div className="ml-4 mb-4">
          <PulseLoader color="gray" size="10px" />
        </div>
      ) : divisionQueues.length === 0 ? (
        <p className="text-gray-700 text-base mb-4">There are no queues to process recordings from</p>
      ) : (
        <div className="flex flex-col space-y-4">
          <p className="text-gray-700 text-base">Recordings will be processed from queues selected below</p>

          {divisionQueues.map((dqs) => (
            <div key={dqs.divisionId} className="mb4">
              <p className="text-gray-700 text-base">
                <strong>{dqs.divisionName}</strong> division
              </p>
              <ul>
                {dqs.queues.map((q) => (
                  <li className="pl-2" key={q.queueId}>
                    <input
                      name={`q-${q.queueId}`}
                      type="checkbox"
                      checked={q.selected}
                      onChange={(e) => {
                        const queueId = q.queueId;
                        if (e.target.checked) {
                          setQueueIds([...queueIds, queueId]);
                        } else {
                          setQueueIds(queueIds.filter((qid) => qid !== queueId));
                        }
                      }}
                    />
                    <label htmlFor={`q-${q.queueId}`} className="pl-1">
                      {q.queueName}
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      )}
    </section>
  );
};
